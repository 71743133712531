import Title from '@components/common/Title'
import { MessageContext } from '@contexts/MessageContext'
import { useUser } from '@contexts/UserContext'
import { api } from '@helpers/api'
import { handleError } from '@helpers/handleError'
import { useGenericContext } from '@hooks/useGenericContext'
import { IOption } from '@types/IOption'
import { Button, Col, Form, Input, Radio, Row, Space } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface IChurn {
  cs_churn_motives: string
  cs_churn_comments: string
}

const MotivesRadio = () => {
  const [data, setData] = useState<IOption[]>()
  const { t } = useTranslation()

  useEffect(() => {

    api.get('/constants')
      .then(e => {
        setData(Object.entries(e.data['CUSTOMER_CHURN_MOTIVES']).map(
          ([, value]) => ({
            value: String(value),
            label: t(String(value))
          }))
        )
      })
  }, [])


  return (
    <Col xs={{ span: 24 }}>
      <Form.Item name="cs_churn_motive" label={t("CSCHURNMOTIVES")} required>
        <Radio.Group>
          <Space direction='vertical'>
            {data?.map((e) => (
              <Radio key={e.value} value={e.value}>{e.label}</Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </Col>
  )
}

const useChurn = () => {
  const navigate = useNavigate()
  const messageApi = useGenericContext(MessageContext)
  const { t } = useTranslation()
  const { user } = useUser()

  useEffect(() => {
    if (user.level > 1) return navigate('/')
    api.get('/churn').then(res => {
      if (!res?.data?.data?.length) navigate('/')
    })
  }, [])

  const onFinish = (values: IChurn) => {
    api
      .post('/churn', values)
      .then(() => {
        messageApi.success(t('POSTCHURNSUCCESS'))
        navigate('/')
      })
      .catch(e => {
        handleError(e, messageApi, t('POSTCHURNERROR'))
      })
  }

  return { onFinish }
}

const Churn = () => {
  const { onFinish } = useChurn()
  const [form] = Form.useForm()
  const { t } = useTranslation()

  return (
    <Form onFinish={onFinish} layout="vertical" form={form}
      labelCol={{style:{fontWeight:'bold'}}}
    >
      <Row
        style={{
          width: '100%',
          marginTop: '20px'
        }}
        justify="center"
        align="middle"
      >
        <Col xs={{ span: 24 }} xl={{ span: 12 }}>
          <Row justify="center">
            <Title name={t('CHURNFORM')} />
          </Row>
          <MotivesRadio />
          <Col xs={{ span: 24 }}>
            <Form.Item
              name="cs_churn_comments"
              rules={[{ required: true, message: t('requiredItem') }]}
              label={t('CSCHURNCOMMENTS')}
            >
              <Input.TextArea size="large" rows={2} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button
              onClick={form.submit}
              data-cy="send-churn"
              type="primary"
              block
            >
              {t('SENDFORMCHURN')}
            </Button>
          </Col>
        </Col>
      </Row>
    </Form>
  )
}

export default Churn
