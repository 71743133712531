import { useContext } from 'react'
import { Form } from 'antd'
import { ModalContext } from '@contexts/ModalContext'
import { useGenericContext } from '@hooks/useGenericContext'
import useDisableButtonModal from '@hooks/useDisableButtonModal'
import { ZoneContext } from '@contexts/ZoneContext'
import { SelectInput } from '@components/common/SelectInput'
import useForm from '@hooks/useForm'
import InputWithForm from '@components/common/InputWithForm'
import { useTranslation } from 'react-i18next'

interface CreateDnsProps {
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateDns = ({ setDisabled, ...props }: CreateDnsProps) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { onCancel } = useGenericContext(ModalContext)
  const { zoneName } = useContext(ZoneContext)

  const close = () => {
    form.resetFields()
    if (onCancel) onCancel()
  }

  const { onFinish, contextHolder, disabled } = useForm(
    'create',
    close,
    `/dns/zone/${zoneName}/record`
  )

  useDisableButtonModal(setDisabled, disabled)

  const handleFinish = async (values: any) => {
    await onFinish(values)
  }
  return (
    <Form
      {...props}
      {...{ form, disabled, layout: 'vertical' }}
      onFinish={handleFinish}
    >
      {contextHolder}
      <InputWithForm
        {...props}
        rules={[
          {
            required: true,
            pattern: /^[^A-Z]*$/,
            message: t('HOSTNAMELOWERCASE')
          }
        ]}
        label={t('HOSTNAME')}
        name={'hostname'}
      />
      <SelectInput
        constant="DNS_TYPES"
        inputName="type"
        label="Type"
        required
        span={24}
      />
      <InputWithForm
        {...props}
        rules={[{ required: true }]}
        label={t('VALUE')}
        name={'data'}
      />
    </Form>
  )
}

export default CreateDns
