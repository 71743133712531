import { createContext, useState, useMemo, ReactNode } from 'react'

const ZoneContext = createContext<{
  zoneName: string
  setZoneName: React.Dispatch<React.SetStateAction<string>>
}>({
  zoneName: 'opendata.center.',
  setZoneName: () => {}
})

const ZoneProvider = ({ children }: { children: ReactNode }) => {
  const [zoneName, setZoneName] = useState('opendata.center.')
  const memoZone = useMemo(
    () => ({ zoneName, setZoneName }),
    [zoneName, setZoneName]
  )

  return (
    <ZoneContext.Provider value={memoZone}>{children}</ZoneContext.Provider>
  )
}

export { ZoneContext, ZoneProvider }
