import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import FormOnboard from '@components/OnBoard/FormOnboard'
import { api } from '@helpers/api'
import { Navbar, Image, Logo } from './style'
import { useUser } from '@contexts/UserContext'
import { Col } from 'antd'
import FeedbackContext from '@contexts/FeedbackContext'
import MessageProvider from '@contexts/MessageContext'

const useOnboardData = () => {
  const [companyUuid, setCompanyUuid] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [hasCompany, setHasCompany] = useState(false)
  const [onboardStatus, setOnboardStatus] = useState('')
  const { setIsThemeDark, user } = useUser()

  const navigate = useNavigate()

  useEffect(() => {
    if (user.level === 1) {
      api.get('/onboard/customer').then(response => {
        if (response.data.length) {
          const company = response.data[0]
          setCompanyUuid(company.uuid)
          setOnboardStatus(company.onboard_status)
          setShowForm(true)
          setHasCompany(true)
          setIsThemeDark(false)

          navigate(`/onboard/customer/${company.uuid}`, {
            state: response.data
          })
        }
      })
    }
  }, [user.level, navigate, setIsThemeDark])

  return { companyUuid, showForm, hasCompany, onboardStatus }
}

const FormNavbar = () => {
  return (
    <Navbar>
      <Logo
        src="/assets/images/logo-opendatacenter-white.png"
        alt="Logo OPEN"
        data-cy="onboard-logo"
      />
    </Navbar>
  )
}

const FormImage = () => {
  return (
    <Image
      src="/assets/images/Email-CS_Fluxograma-4.png"
      alt="Imagem 4 da jornada"
      style={{ paddingBottom: '2em', paddingTop: '2em' }}
      data-cy="journey-image"
    />
  )
}

const Onboard = () => {
  const { companyUuid, showForm, onboardStatus } = useOnboardData()
  const { user } = useUser()

  return (
    <FeedbackContext>
      {user.level === 1 ? (
        <MessageProvider>
          <FormNavbar />
          {onboardStatus === 'WAITING_FIRST_DEVICE_ACCESS_MAIL' && showForm && (
            <Col display="flex" flexDirection="colum" alignItems="center">
              <FormImage />
            </Col>
          )}
          {showForm && <FormOnboard companyUuid={companyUuid} />}
        </MessageProvider>
      ) : null}
    </FeedbackContext>
  )
}

export default Onboard
