import Title from '@common/Title'
import { useTranslation } from 'react-i18next'
import { Row, Tabs } from 'antd'
import { renderingTabFilter } from '@components/Server/Table'
import type { TabsProps } from 'antd'
import CompaniesSelect from '@components/common/CompaniesSelect'
import SearchByQueryInput from '@components/common/SearchByQueryInput'
import { DeviceContext } from '@contexts/DeviceContext'
import { useState, useMemo } from 'react'
import { IDevice } from '@/types/IDevice'
import Device from '@pages/Device'
import DrawerProvider from '@contexts/DrawerContext'
import DeviceHead from '@components/Device/DeviceHead'

const ServerLogs = () => {
  const [data, setData] = useState<IDevice>()
  const { t } = useTranslation()
  const renderingWithTab: TabsProps['items'] = renderingTabFilter(
    'device?sort=updated_at&trashed=true'
  )
  const contextValue = useMemo(() => ({ data, setData }), [data, setData])
  return (
    <DeviceContext.Provider value={contextValue}>
      <DrawerProvider component={Device} head={DeviceHead}>
        <Title name={t('SERVERLOGS')} />
        <Row gutter={[8, 8]}>
          <CompaniesSelect span={8} />
          <SearchByQueryInput />
        </Row>
        <Tabs
          destroyInactiveTabPane={true}
          defaultActiveKey="1"
          items={renderingWithTab}
          type="card"
          size="large"
        />
      </DrawerProvider>
    </DeviceContext.Provider>
  )
}

export default ServerLogs
