import { ModalContext } from '@contexts/ModalContext'
import useModal from '@hooks/useModal'
import { ButtonProps } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '@components/common/Modal/index.tsx'
import ModalFooter from '@components/common/ModalFooter/index.tsx'
import { CustomButton } from '@components/Ticket/Modal/index.tsx'
import Icon from '@components/common/Icon'
import PodForm from '../Form'
import { useUser } from '@contexts/UserContext'
import { IProductPod } from '@/types/IProduct'

interface ModalPodProps extends Omit<ButtonProps, 'type'> {
  data: IProductPod
  onClose?: () => void
}

const ModalPod = ({ data, onClose, ...props }: ModalPodProps) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { open, onCancel, onOpen } = useModal()

  const numForm = (
    crypto.getRandomValues(new Uint32Array(1))[0] % 10000
  ).toString()
  const memoOpen = useMemo(
    () => ({ open, onClose, onOpen }),
    [open, onClose, onOpen]
  )

  return (
    <ModalContext.Provider value={memoOpen}>
      <Modal
        title={`${t('ADDPOD')} - ${data.price} ${t('PERHOUR')}`}
        open={open}
        footer={
          <ModalFooter onClose={onCancel} action={'create'} form={numForm} />
        }
        onCancel={onCancel}
        centered
        width="30%"
      >
        <PodForm
          company={user.companies[0].uuid}
          data={data}
          onCancel={onCancel}
          numForm={numForm}
          onClose={onClose}
        />
      </Modal>
      <CustomButton
        disabled={!data.available}
        data-cy={`modal-pod`}
        onClick={onOpen}
        block
        type="primary"
        style={{ width: 200, marginBottom: 5 }}
        icon={
          <Icon
            name="far fa-rocket-launch"
            color={data.available ? 'white' : 'gray'}
          />
        }
        {...props}
      />
    </ModalContext.Provider>
  )
}

export default ModalPod
