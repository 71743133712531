import { PropsWithChildren, createContext, useEffect, useState } from 'react'
import { INotification, NotificationContextType } from '@/types/INotification'
import { api } from '@helpers/api'
import useSocket from '@hooks/useSocket'
import { notification } from 'antd'

type Filter = {
  service_table: string
  key: string
  value: string
}
export const NotificationContext = createContext<
  NotificationContextType | undefined
>(undefined)

const NotificationsProvider = ({ children }: PropsWithChildren) => {
  const [data, setData] = useState<INotification[] | undefined>(undefined)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState<Filter | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [notify, contextHolder] = notification.useNotification()

  const handleNotification = (notificationData: NotificationContextType) => {
    setCount(prev => prev + 1)
    notify.info({
      message: notificationData.model.title,
      description: notificationData.model.summary,
      placement: 'bottomRight',
      duration: 5,
      showProgress: true,
      pauseOnHover: true,
      className: 'notification-info'
    })
  }

  useEffect(() => {
    api
      .get('/notification/countUnread')
      .then(e => {
        setCount(e.data.count)
      })
      .finally(() => setLoading(false))
  }, [])

  useSocket(handleNotification)
  return (
    <NotificationContext.Provider
      value={{
        data,
        setData,
        loading,
        count,
        setCount,
        page,
        setPage,
        setLoading,
        filter,
        setFilter
      }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationsProvider
