import { IUserTiers } from '@/types/IUserTiers'
import Icon from '@components/common/Icon'
import IconButton from '@components/common/IconButton'
import { generateTabItem } from '@components/common/Tab'
import { MessageContext } from '@contexts/MessageContext'
import { api } from '@helpers/api'
import { handleError } from '@helpers/handleError'
import { useGenericContext } from '@hooks/useGenericContext'
import useTableContext from '@hooks/useTableContext'
import { Button, Popconfirm, Space, Tag, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import TiersModal from '../Modal'

const actionsProps = {
  title: 'ACTIONS',
  dataIndex: 'uuid',
  key: 'uuid'
}

const TargetTiers = ({ targets }: { targets: IUserTiers['targets'] }) => {
  return (
    <Space wrap>
      {targets
        ? targets.map(e => (
            <Tag key={e.slug} color="blue-inverse">
              {e.name}
            </Tag>
          ))
        : null}
    </Space>
  )
}

export const TierUsers = ({ users }: { users: IUserTiers['users'] }) => {
  const { t } = useTranslation()
  return (
    <Space wrap>
      {users
        ? users.map(user => (
            <Tooltip
              title={
                <>
                  {t('EMAIL')}: {user.email}
                  <br />
                  {t('LEVEL')}: {user.level}
                  <br />
                  {t('SECTORS')}: {user.sectors.map(e => ' ' + e.sector?.slug)}
                </>
              }
            >
              <Tag color="blue-inverse">{user.name + ' - ' + user.level}</Tag>
            </Tooltip>
          ))
        : null}
    </Space>
  )
}

const useDeleteTier = (data: IUserTiers) => {
  const { t } = useTranslation()
  const messageApi = useGenericContext(MessageContext)
  const { remove } = useTableContext()

  const onDelete = () => {
    api
      .delete(`/user/tier/${data.uuid}`)
      .then(() => {
        messageApi.success(t('SUCCESSDELETETIER'))
        remove(data.uuid)
      })
      .catch(e => handleError(e, messageApi, t('ERRORDELETINGTIER')))
  }
  return onDelete
}

const DeleteTier = ({ data }: { data: IUserTiers }) => {
  const { t } = useTranslation()
  const onDelete = useDeleteTier(data)
  return (
    <Popconfirm
      title={`${t('AREYOUSUREDELETETIER')} ${data.name}`}
      onConfirm={onDelete}
    >
      <IconButton name="fa-light fa-trash" danger data-cy="delete-tier" />
    </Popconfirm>
  )
}

const Actions = ({ data }: { data: IUserTiers }) => {
  return (
    <Space>
      <TiersModal data={data} type="primary" data-cy="open-edit-tier" />
      <DeleteTier data={data} />
    </Space>
  )
}
const useInactiveTier = (data: IUserTiers) => {
  const { t } = useTranslation()
  const { remove } = useTableContext()
  const messageApi = useGenericContext(MessageContext)
  const restore = async () => {
    await api
      .post(`/user/tier/${data.uuid}/restore`)
      .then(e => {
        messageApi.success(t('RESTORETIERSUCCESS'))
        remove(e.data.uuid)
      })
      .catch(e => handleError(e, messageApi, t('ERRORINACTIVETIER')))
  }
  return restore
}

const InactiveActions = ({ data }: { data: IUserTiers }) => {
  const restore = useInactiveTier(data)
  const theme = useTheme()
  return (
    <Space>
      <Button
        style={{ backgroundColor: theme.green }}
        onClick={restore}
        data-cy="restore-inative-action"
      >
        <Icon name="fa-light fa-recycle" color="white" />
      </Button>
    </Space>
  )
}

const TierLabels = ({ labels }: { labels: IUserTiers['labels'] }) => {
  return (
    <Space wrap>
      {labels
        ? labels.map(e => (
            <Tag color="blue-inverse" key={e}>
              {e}
            </Tag>
          ))
        : null}
    </Space>
  )
}

export const commonColumns: ColumnsType<IUserTiers> = [
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'SLUG',
    dataIndex: 'slug',
    key: 'slug'
  },
  {
    title: 'LABELS',
    dataIndex: 'labels',
    key: 'labels',
    render: labels => <TierLabels labels={labels} />
  },
  {
    title: 'TARGETS',
    dataIndex: 'targets',
    key: 'targets',
    render: targets => <TargetTiers targets={targets} />
  },
  {
    title: 'USERS',
    dataIndex: 'users',
    key: 'users',
    render: users => <TierUsers users={users} />
  }
]

export const useTiersTab = () => {
  const { t } = useTranslation()
  const names = ['ACTIVES', 'INACTIVES']
  const urls = ['/user/tier', '/user/tier?inactive=1']
  return names.map((e, index) =>
    generateTabItem(
      {
        key: index,
        label: t(e),
        url: urls[index]
      },
      [
        {
          ...actionsProps,
          render: (_, data) =>
            e === 'ACTIVES' ? (
              <Actions {...{ data }} />
            ) : (
              <InactiveActions {...{ data }} />
            )
        },
        ...commonColumns
      ]
    )
  )
}
