import { ICompany } from '@/types/ICompany'
import { IDevice } from '@/types/IDevice'
import DeviceIcons from '@components/Device/Icons'
import { api } from '@helpers/api'
import { Descriptions, DescriptionsProps, List } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface AssociationWarningProps {
  text: string
  company: string
}

const AssociationWarning: React.FC<AssociationWarningProps> = ({
  text,
  company
}) => {
  const { t } = useTranslation()
  const [companyData, setCompanyData] = useState<ICompany | null>(null)
  const [devices, setDevices] = useState<IDevice[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/company/${company}`)
        setCompanyData(response.data)
        const devicesResponse = await api.get(`/device?company=${company}`)
        setDevices(
          Array.isArray(devicesResponse.data.data)
            ? devicesResponse.data.data
            : []
        )
      } catch (error) {
        console.error('Failed to fetch company data:', error)
      }
    }

    if (company) {
      fetchData()
    }
  }, [company])

  const borderedItems: DescriptionsProps['items'] = [
    {
      key: '1',
      label: t('USERS'),
      span: { xl: 4, lg: 4 },
      children:
        companyData?.users?.map(user => user.name).join(', ') ?? t('NOUSERS')
    }
  ]

  return (
    <>
      <p>{text}</p>
      <Descriptions items={borderedItems} />
      {t('DEVICES')}:{' '}
      <List
        grid={{ gutter: 16, column: 2 }}
        size="small"
        itemLayout="vertical"
        dataSource={devices}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <DeviceIcons
                  kind={(item.os?.kind as keyof typeof DeviceIcons) || 'OTHER'}
                />
              }
              title={item.name}
            />
          </List.Item>
        )}
      />
    </>
  )
}

export default AssociationWarning
