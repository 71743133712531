import omit from 'lodash/omit'
import { useTranslation } from 'react-i18next'
import { api } from '@helpers/api'
import {
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  FormInstance,
  Switch,
  Select
} from 'antd'
import type { ICreateForm } from '@/types/ICreateForm'
import type { IOption } from '@/types/IOption'
import TextInput from '@common/TextInput'
import { ICompany } from '@/types/ICompany'
import { cpf, cnpj } from 'cpf-cnpj-validator'
import PhonesForm from '@common/PhonesForm'
import EmailsForm from '@common/EmailsForm'
import FormUser from '../FormUser'
import useCompanyForm from '@hooks/useCompanyForm'
import { ORIGINREASON } from '@constants/originreason'
import dayjs from 'dayjs'
import { SearchSelect } from '@components/User/Form'
import { SelectInput } from '@components/common/SelectInput'

const inputFields = [
  'name',
  'fantasy_name',
  'address',
  'city',
  'estate',
  'country',
  'alias',
  'obs',
  'obs_relationship',
  'software',
  'sector'
]

interface CreateCompanyProps extends ICreateForm {
  data: ICompany | undefined
}

const filterUsers = (data: ICompany) => {
  return data?.users?.map(user => {
    const filter = omit(user, ['entity', 'companies', 'preferences', 'sectors'])
    const user_in_charge = data?.user_in_charge?.uuid === user.uuid
    return {
      ...filter,
      birthday: user?.birthday ? dayjs(user.birthday) : undefined,
      user_in_charge: user_in_charge
    }
  })
}

const handleFinish = async (
  values: ICompany,
  onFinish: (object?: any) => void
) => {
  const handleUsers = () => {
    return values?.users?.map(user => {
      return {
        ...user,
        birthday: dayjs(values.migration_date).format('YYYY-MM-DD')
      }
    })
  }
  const data = {
    ...values,
    users: handleUsers()
  }
  onFinish(data)
}

const setInititalValues = (data: ICompany | undefined) => {
  if (data?.name) {
    const filteredUsers = filterUsers(data)
    return {
      ...data,
      user_in_charge: data.user_in_charge ? data.user_in_charge.uuid : null,
      users: filteredUsers,
      migration_date: data.migration_date
        ? dayjs(data.migration_date)
        : undefined
    }
  }
}

const CompanyTagsSelect = () => {
  const { t } = useTranslation()
  return (
    <SelectInput
      span={8}
      label={t('TAGS')}
      constant="COMPANY_TAGS"
      inputName='tags'
      mode='multiple'
      required={false}
      allowClear
    />
  )
}

const FormCompany = ({
  onClose,
  action,
  data,
  ...props
}: CreateCompanyProps) => {
  const close = () => {
    form.resetFields()
    onClose()
  }
  const { onFinish, disabled, contextHolder } = useCompanyForm(
    action,
    close,
    data
  )

  const { t } = useTranslation()
  const [form] = Form.useForm()
  const initialValues = setInititalValues(data)
  const fieldrules = ['name', 'address', 'city', 'estate']
  return (
    <Form
      {...props}
      form={form}
      disabled={disabled}
      layout="vertical"
      initialValues={initialValues}
      onFinish={values => handleFinish(values, onFinish)}
    >
      {contextHolder}
      <Row gutter={12}>
        <CEPInput form={form} />
        <DocnumInput />
        <CompanyTagsSelect />
        {inputFields.map(item => {
          const isFieldRequired = fieldrules.includes(item)

          return (
            <Col key={item} xs={{ span: 24 }} xl={{ span: 8 }}>
              <TextInput
                key={item}
                label={t(item.toUpperCase())}
                name={item}
                rules={[
                  {
                    required: isFieldRequired,
                    message: t('requiredItem')
                  }
                ]}
              />
            </Col>
          )
        })}
        <Col xs={{ span: 24 }} xl={{ span: 8 }} lg={{ span: 8 }}>
          <Form.Item
            name="migration_date"
            label={t('MIGRATION_DATE')}
            rules={[{ required: true, message: t('requiredItem') }]}
          >
            <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD HH:mm" />
          </Form.Item>
        </Col>
        {action === 'edit' && (
          <Col xs={{ span: 24 }} xl={{ span: 8 }} lg={{ span: 8 }}>
            <SearchSelect
              require={true}
              name="USER_IN_CHARGE"
              searchUrl={`user?company=${data?.uuid}&`}
              initialValue={
                data?.user_in_charge
                  ? [
                    {
                      value: data?.user_in_charge?.uuid,
                      label: data?.user_in_charge?.name
                    }
                  ]
                  : []
              }
              placeholder={t('FILTERBYUSER')}
            />
          </Col>
        )}

        <OriginReason />
        {/* <Col xs={24} lg={12}>
          <CompanySelect name="partner"  />
        </Col> */}
        <Col xs={{ span: 24 }}>
          <Form.Item
            initialValue={false}
            name={'has_support'}
            label={t('HASSUPPORT')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
        <PhonesForm />
        <EmailsForm />
        {action !== 'edit' && (
          <FormUser
            title="USER"
            action={action}
            data={data?.users}
            nameForm="users"
            form={form}
          />
        )}
      </Row>
    </Form>
  )
}

const CEPInput = ({ form }: { form: FormInstance }) => {
  const { t } = useTranslation()

  const getCEP = async (cep: string) => {
    const response = await api.get(`https://viacep.com.br/ws/${cep}/json/`)
    const data = response.data
    if (data) {
      form.setFieldsValue({
        address: data.logradouro,
        city: data.localidade,
        estate: data.uf,
        country: 'Brasil'
      })
    }
  }

  return (
    <Col xs={{ span: 24 }} xl={{ span: 8 }}>
      <Form.Item
        name="cep"
        label="CEP"
        rules={[{ required: true, message: t('requiredItem') }]}
      >
        <Input onBlur={e => getCEP(e.target.value)} />
      </Form.Item>
    </Col>
  )
}

const DocnumInput = () => {
  const { t } = useTranslation()
  return (
    <Col xs={{ span: 24 }} xl={{ span: 8 }}>
      <Form.Item
        label="Cpf/Cnpj"
        name="docnum"
        rules={[
          { required: true, message: t('requiredItem') },
          {
            validator(_, value) {
              if (cpf.isValid(value) || cnpj.isValid(value)) {
                return Promise.resolve()
              } else {
                return Promise.reject(t('INVALID_DOCNUM'))
              }
            }
          }
        ]}
      >
        <Input />
      </Form.Item>
    </Col>
  )
}

const OriginReason = () => {
  const { t } = useTranslation()
  const options: IOption[] = ORIGINREASON.map(item => ({
    value: t(item),
    label: t(item)
  }))
  const rules = [{ required: true, message: t('requiredItem') }]
  return (
    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
      <Form.Item rules={rules} name="origin_reason" label={t('ORIGIN_REASON')}>
        <Select
          placeholder={t('CHOOSEANITEM')}
          options={options}
          data-cy="select-input"
        />
      </Form.Item>
    </Col>
  )
}

export default FormCompany
