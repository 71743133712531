import { Form, Row, Col, message, Select, SelectProps, Typography } from 'antd'
import { api } from '@helpers/api'
import { useTranslation } from 'react-i18next'
import type { IProductPod } from '@/types/IProduct'
import TextMarkdown from '@components/common/TextMarkdown'

type Image = {
  uuid: string
  name: string
}

type Option = SelectProps['options']

const transformImagesToOptions = (images: Image[]): Option => {
  return images.map(image => ({
    value: image.uuid,
    label: image.name
  }))
}

interface IPodForm {
  company: string
  data: IProductPod
  onCancel: () => void
  numForm: string
  onClose?: () => void
}

const PodForm = ({ company, data, onCancel, numForm, onClose }: IPodForm) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const options = transformImagesToOptions(data.images)

  const handleFinish = async (values: IProductPod) => {
    await api
      .post('/pod/order', {
        ...values,
        product: data.uuid,
        company: company,
        dc: data.dcs[0]
      })
      .then(() => {
        message.success(t('SUCCESSPOD'))
        onCancel()
        onClose && onClose()
      })
      .catch(() => {
        message.error(t('ERRORCREATEPOD'))
      })
  }

  return (
    <Form
      name="autofill"
      layout="vertical"
      initialValues={{ image: data.images[0].uuid }}
      form={form}
      onFinish={handleFinish}
      id={numForm}
    >
      <Row style={{ width: '100%' }}>
        <Col {...{ span: 24 }}>
          <Form.Item
            rules={[{ required: true, message: t('requiredItem') }]}
            name="image"
            label="Template"
          >
            <Select
              placeholder={t('CHOOSEANITEM')}
              options={options}
              data-cy="select-input"
            ></Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Typography.Text>{`${t('DESCRIPTION')}:`}</Typography.Text>
          <TextMarkdown text={data.description} />
        </Col>
      </Row>
    </Form>
  )
}

export default PodForm
