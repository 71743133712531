import { ColumnsType } from 'antd/es/table'
import { ICompany, IEntity } from '@/types/ICompany'
import { columns } from '@components/Company/Table'
import { TableWithoutQuery } from '@components/common/Table'
import { User } from '@/types/IUser'
import Actions from '../Actions'

const userColumns: ColumnsType<User> = [
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'EMAIL',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'PHONES',
    dataIndex: 'phones',
    key: 'phones',
    render: phones => (
      <span>
        {Array.isArray(phones) && phones.length > 0 ? phones.join(', ') : ''}
      </span>
    )
  }
]
export const entityColumns: ColumnsType<IEntity> = [
  {
    title: 'ACTIONS',
    dataIndex: 'name',
    key: 'actions',
    render: (_, entity) => <Actions entity={entity} />
  },
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'COMPANIES',
    dataIndex: 'companies',
    key: 'companies',
    render: (companies: ICompany[]) => (
      <TableWithoutQuery columns={columns.slice(0, 2)} data={companies} />
    )
  },
  {
    title: 'USERS',
    dataIndex: 'users',
    key: 'users',
    render: (users: User[]) => (
      <TableWithoutQuery columns={userColumns} data={users} />
    )
  }
]
