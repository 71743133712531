import { IReceipt } from '@/types/IReceipt'
import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import ResponsiveTableCard from '@common/ResponsiveTableCard'
import { formatDate } from '@components/Dns/Tabs'
import { Tag } from 'antd'

const ReceiptTable = () => {
  const { t } = useTranslation()

  const getStatusTag = (status: string) => {
    switch (status) {
      case 'OK':
        return <Tag color="green">{t(status)}</Tag>
      case 'FAILED':
        return <Tag color="red">{t(status)}</Tag>
      default:
        return <Tag color="gray">{t(status)}</Tag>
    }
  }

  const getTypeForValue = (value: number, type: string, status: string) => {
    
    const formattedValue = value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 3
    })

    if (status === 'OPEN') {
      return <span style={{ color: 'gray' }}>{formattedValue}</span>
    }

    switch (type) {
      case 'ADD_BALANCE':
        return <span style={{ color: 'green' }}>{formattedValue}</span>
      case 'REMOVE_BALANCE':
        return <span style={{ color: 'red' }}>{formattedValue}</span>
      default:
        return <span style={{ color: 'gray' }}>{formattedValue}</span>
    }
  }

  const columns: ColumnsType<IReceipt> = [
    {
      title: t('COMPANY'),
      dataIndex: 'company',
      key: 'company',
      render: company => <span>{company.name}</span>
    },
    {
      title: t('VALUE'),
      dataIndex: 'value',
      key: 'value',
      render: (_, receipt) => {
        return getTypeForValue(receipt.value, receipt.type, receipt.status)
      }
    },
    {
      title: t('TYPE'),
      dataIndex: 'type',
      key: 'type',
      render: type => <span>{t(type)}</span>
    },
    {
      title: t('ORIGIN'),
      dataIndex: 'origin',
      key: 'origin',
      render: origin => <span>{t(origin)}</span>
    },
    {
      title: t('STATUS'),
      dataIndex: 'status',
      key: 'status',
      render: status => <>{getStatusTag(status)}</>
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: created_at => <span>{formatDate(created_at)}</span>
    }
  ]

  return (
    <ResponsiveTableCard
      {...{
        columns: columns,
        url: '/balance/receipt'
      }}
    />
  )
}

export default ReceiptTable
