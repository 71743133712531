import { Row, Col, Button } from 'antd'
import Title from '@common/Title'
import { useTranslation } from 'react-i18next'
import FormCompany from '@components/Company/Form'

const AcademicPartner = () => {
  const crypto = window.crypto
  let array = new Uint32Array(1)
  const numForm = crypto.getRandomValues(array)[0].toString()
  const { t } = useTranslation()
  const params = new URLSearchParams(window.location.search)
  const data = {
    name: params.get('name'),
    emails: [params.get('email')],
    phones: [params.get('phone')],
    docnum: params.get('cpf'),
    cep: params.get('cep'),
    address: params.get('address'),
    tags: [params.get('tags')]
  }
  return (
    <Row gutter={[16, 16]}>
      <Col xl={12} xs={24}>
        <Title name={t('CREATE_PARTNER')} />
      </Col>
      <Col xl={{ span: 2, offset: 10 }} xs={24}>
        <Button type="primary" key="submit" htmlType="submit" form={numForm}>
          {t('CONFIRM')}
        </Button>
      </Col>
      <Col span={24}>
        <FormCompany id={numForm} data={data} action="create" />
      </Col>
      <Col span={24}></Col>
    </Row>
  )
}

export default AcademicPartner
