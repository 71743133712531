export const DEVICETYPES = {
  OTHER: { icon: 'fa-solid fa-circle-question', color: 'gray'},
  WINDOWS: { icon: 'fa-brands fa-windows', color: 'blue-inverse'},
  LINUX: { icon: 'fa-brands fa-linux', color: '#1c1c1c'},
  BSD: { icon: 'fa-brands fa-freebsd', color: '#c71a1a'},
  UNIX: { icon: 'fa-brands fa-uniregistry', color: '#1c1c1c'},
  VMWARE: { icon: 'fa-solid fa-server', color: '#F28C01'},
  FIREWALL: { icon: 'fa-solid fa-block-brick-fire', color: '#63373d'},
  
}
