import { Button, ButtonProps, Col, Form } from 'antd'
import Modal from '@common/Modal'
import useModal from '@hooks/useModal'
import { IEntity } from '@/types/ICompany'
import ModalFooter from '@components/common/ModalFooter'
import useForm from '@hooks/useForm'
import TextInput from '@components/common/TextInput'
import { useTranslation } from 'react-i18next'

interface ModalCompanyProps extends ButtonProps {
  action: 'edit'
  data?: IEntity
}

const inputFields = ['name', 'alias', 'obs']

const EntityModal = ({ action, data, ...props }: ModalCompanyProps) => {
  const numForm = (
    crypto.getRandomValues(new Uint32Array(1))[0] % 10000
  ).toString()
  const { open, onOpen, onCancel } = useModal()
  const { t } = useTranslation()
  const close = () => {
    form.resetFields()
    onCancel()
  }

  const [form] = Form.useForm()
  const { onFinish, disabled, contextHolder } = useForm(
    action,
    close,
    `/entity/${data?.uuid}`
  )
  const fieldrules = ['name', 'alias']

  return (
    <>
      <Modal
        footer={
          <ModalFooter onClose={onCancel} action={action} form={numForm} />
        }
        width="30%"
        open={open}
        onCancel={onCancel}
      >
        <Form
          {...props}
          id={numForm}
          form={form}
          disabled={disabled}
          layout="vertical"
          onFinish={onFinish}
          initialValues={data}
        >
          {contextHolder}
          {inputFields.map(item => {
            const isFieldRequired = fieldrules.includes(item)
            return (
              <Col key={item} xs={{ span: 24 }}>
                <TextInput
                  key={item}
                  label={t(item.toUpperCase())}
                  name={item}
                  rules={[
                    {
                      required: isFieldRequired,
                      message: t('requiredItem')
                    }
                  ]}
                  valuePropName="value"
                />
              </Col>
            )
          })}
        </Form>
      </Modal>
      <Button {...props} onClick={onOpen} />
    </>
  )
}

export default EntityModal
