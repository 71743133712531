import { Space, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { DATEFORMAT } from '@constants/dateformat'
import { ICompany } from '@/types/ICompany'
import { CompanyTag } from '@components/Ticket/Table'
import { Tooltip } from 'antd/lib'
import { IUser } from '@/types/IUser'
import { useTranslation } from 'react-i18next'

const common = { dataIndex: 'counts' }

interface UserInChargeProps {
  user: IUser
}

export const UserInCharge: React.FC<UserInChargeProps> = ({ user }) => {
  const { t } = useTranslation()
  return (
    <Tooltip
      title={
        <>
          {t('EMAIL')}: {user.email}
          <br />
          {t('NAME')}: {user.name}
          <br />
          {t('PHONES')}:{' '}
          {Array.isArray(user.phones) && user.phones.length > 0
            ? user.phones.join(', ')
            : ''}
        </>
      }
    >
      <Tag color="blue-inverse">{user.name}</Tag>
    </Tooltip>
  )
}

export const columns: ColumnsType<ICompany> = [
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name',
    render: (_, company) => (
      <Space wrap align="center">
        <CompanyTag company={company} />
        {company.tags
          ? company.tags.map(tag => (
              <Tag color="lime-inverse" key={tag}>
                #{tag}
              </Tag>
            ))
          : null}
      </Space>
    )
  },
  {
    title: 'USER_IN_CHARGE',
    dataIndex: 'user_in_charge',
    key: 'user_in_charge',
    render: user_in_charge => (
      <>
        {!user_in_charge?.name ? (
          <span> </span>
        ) : (
          <UserInCharge user={user_in_charge} />
        )}{' '}
      </>
    )
  },
  {
    ...common,
    title: 'TICKETS',
    key: 'TICKETS',
    render: counts => {
      return <span>{counts.tickets}</span>
    }
  },
  {
    ...common,
    title: 'DEVICES',
    key: 'DEVICES',
    render: counts => <span>{counts.devices}</span>
  },
  {
    title: 'CREATEDAT',
    dataIndex: 'created_at',
    key: 'created_at',
    render: text => <> {text ? dayjs(text).format(DATEFORMAT) : null} </>
  },
  {
    title: 'DELETEDAT',
    dataIndex: 'deleted_at',
    key: 'created_at',
    render: text => (
      <>
        {' '}
        {text ? <Tag color="red">{dayjs(text).format(DATEFORMAT)}</Tag> : null}
      </>
    )
  }
]
