import { IEntity } from '@/types/ICompany'
import RenderPage from '@components/common/RenderPage'
import Title from '@components/common/Title'
import {
  EntityContext,
  EntityProvider,
  useEntityContext
} from '@contexts/Entity'
import translateTabs from '@helpers/translateTabs'
import { CreateUser } from '@pages/Users'
import { Col, Modal, Row, Tabs } from 'antd'
import { Space, TabsProps } from 'antd/lib'
import { useTranslation } from 'react-i18next'
import { renderingTabFilter as userTab } from '@components/User/Tab'
import Button from '@components/common/Button'
import { useTheme } from 'styled-components'
import Icon from '@components/common/Icon'
import ModalFooter from '@components/common/ModalFooter'
import useModal from '@hooks/useModal'
import AssociationForm from '@components/Entity/AssociationForm'
import { TableAssociation } from '@components/Entity/TableAssociation'
import EntityModal from '@components/Entity/Modal'
import Descriptions from '@components/common/Descriptions'
import { CustomDescriptionType } from '@/types/IDescriptions'
import { useMemo } from 'react'

const ModalAssociation = ({ ...props }: IEntity) => {
  const numForm = (
    crypto.getRandomValues(new Uint32Array(1))[0] % 10000
  ).toString()
  const { open, onOpen, onCancel } = useModal()
  const { t } = useTranslation()
  const { company } = useEntityContext()

  return (
    <>
      <ModalContent
        open={open}
        onCancel={onCancel}
        props={props}
        numForm={numForm}
        company={company}
        warningMessage={t('WARNINGASSOCIAION')}
      />
      <Button {...props} onClick={onOpen} />
    </>
  )
}

const ModalContent = ({
  open,
  onCancel,
  props,
  numForm,
  company,
  warningMessage
}: {
  open: boolean
  onCancel: () => void
  props: IEntity
  numForm: string
  company: any
  warningMessage: string
}) => (
  <Modal
    footer={
      <ModalFooter
        {...props}
        onClose={onCancel}
        action={'create'}
        form={numForm}
        warning
        warningMessage={warningMessage}
        company={company ?? undefined}
      />
    }
    width="30%"
    open={open}
    onCancel={onCancel}
  >
    <AssociationForm
      id={numForm}
      data={props.uuid}
      action={'create'}
      onClose={onCancel}
    />
  </Modal>
)

const CreateAssociation = ({ data }: { data: IEntity }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Col xs={{ span: 24 }} xl={{ span: 24 }}>
      <ModalAssociation
        {...data}
        title={t('CREATECOMPANY')}
        style={{ background: theme.green }}
        type="primary"
        icon={<Icon name="fa-light fa-plus" color="white" />}
        block
        id="createAssociation"
      >
        {t('CREATEASSOCIATION')}
      </ModalAssociation>
    </Col>
  )
}

const UserTab = () => {
  const { entity } = useEntityContext()

  return (
    <>
      <Row justify="end">
        <CreateUser
          data={{
            companies: [{ uuid: entity?.companies[0]?.uuid ?? '' }],
            entity: { uuid: entity?.uuid ?? '' }
          }}
        />
      </Row>
      <Tabs items={userTab(entity?.uuid)} />
    </>
  )
}

export const EntityHead = ({ data }: { data: IEntity }) => {
  const { t } = useTranslation()

  return (
    <EntityProvider>
      <Row style={{ width: '100%' }} justify="space-between" align="middle">
        <Title name={data.name} style={{ margin: 0 }} />
        <Space>
          <EntityModal
            type="primary"
            action="edit"
            icon={<Icon name="fa-light fa-edit" color="white" />}
            data={data}
            data-cy="edit-entity"
            style={{ paddingInline: 47 }}
          >
            {t('EDITENTITY')}
          </EntityModal>
          <CreateAssociation data={data} />
        </Space>
      </Row>
    </EntityProvider>
  )
}

const Entity = ({ data }: { data?: IEntity }) => {
  const { t } = useTranslation()
  const tabs = translateTabs(items, t)

  const entityContextValue = useMemo(
    () => ({
      entity: data ?? undefined,
      setEntity: () => {},
      company: null,
      setCompany: () => {}
    }),
    [data]
  )

  return (
    <EntityContext.Provider value={entityContextValue}>
      <RenderPage {...{ data: data, loading: false, error: undefined }}>
        <Descriptions
          style={{ paddingBottom: 15 }}
          columns={entityColumns}
          bordered
          {...{
            dataSource: data,
            size: 'small',
            column: { xs: 1, xl: 4 }
          }}
        />
        <Tabs {...{ items: tabs, type: 'card' }} destroyInactiveTabPane />
      </RenderPage>
    </EntityContext.Provider>
  )
}

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'COMPANIES',
    children: <TableAssociation />
  },
  {
    key: '2',
    label: 'USERS',
    children: <UserTab />
  }
]

const entityColumns: CustomDescriptionType<IEntity>[] = [
  {
    span: 1,
    label: 'NAME',
    key: 'name'
  },
  {
    span: 1,
    label: 'ALIAS',
    key: 'alias'
  },
  {
    span: 1,
    label: 'OBS',
    key: 'obs',
    render: ({ data }) => data
  }
]

export default Entity
