import { ICreateForm } from '@/types/ICreateForm'
import useForm from '@hooks/useForm'
import { Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { SearchSelect } from '@components/User/Form'
import { useEntityContext } from '@contexts/Entity'

interface CreateAssociationProps extends ICreateForm {
  data: string
}

const AssociationForm = ({
  onClose,
  action,
  data,
  ...props
}: CreateAssociationProps) => {
  const close = () => {
    form.resetFields()
    onClose()
  }
  const { company, setCompany } = useEntityContext()

  const { t } = useTranslation()
  const { onFinish, disabled, contextHolder } = useForm(
    action,
    close,
    `/entity/company/${data}/${company}`
  )

  const handleCompanyChange = (value: string) => {
    setCompany(value)
  }

  const [form] = Form.useForm()

  return (
    <Form
      {...props}
      form={form}
      disabled={disabled}
      layout="vertical"
      onFinish={onFinish}
    >
      {contextHolder}
      <Col>
        <SearchSelect
          require={true}
          name="COMPANY"
          searchUrl="company?"
          initialValue={[]}
          onChange={handleCompanyChange}
          placeholder={t('FILTERBYCOMPANY')}
        />
      </Col>
    </Form>
  )
}

export default AssociationForm
