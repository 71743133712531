import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import ResponsiveTableCard from '@common/ResponsiveTableCard'
import Title from '@common/Title'
import SearchByQueryInput from '@components/common/SearchByQueryInput'
import { ICompany } from '@/types/ICompany'
import Icon from '@common/Icon'
import { useTheme } from 'styled-components'
import ModalCompany from '@components/Company/Modal'
import DrawerProvider from '@contexts/DrawerContext'
import Company, { CompanyHead } from '@pages/Company'
import { entityColumns } from '@components/Entity/Table'
import Entity, { EntityHead } from '@pages/Entity'

export const CreateCompany = ({ data }: { data?: ICompany }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Col xs={{ span: 24 }} xl={{ span: 3, offset: 13 }}>
      <ModalCompany
        data={data}
        title={t('CREATECOMPANY')}
        action="create"
        style={{ background: theme.green }}
        type="primary"
        children={t('CREATECOMPANY')}
        icon={<Icon name="fa-light fa-plus" color="white" />}
        block
        id="createcompany"
      />
    </Col>
  )
}

const Companies = () => {
  const { t } = useTranslation()
  return (
    <DrawerProvider component={Company} head={CompanyHead}>
      <DrawerProvider component={Entity} head={EntityHead}>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Title name={t('Clients')} />
          </Col>
          <SearchByQueryInput />
          <CreateCompany />
          <Col span={24}>
            <ResponsiveTableCard
              {...{
                columns: entityColumns,
                url: '/entity?companies=true&users=true'
              }}
            />
          </Col>
        </Row>
      </DrawerProvider>
    </DrawerProvider>
  )
}

export default Companies
