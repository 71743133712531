import { Tag } from 'antd'
import Icon from '@common/Icon'
import { DEVICETYPES } from '@constants/devicetypes'
import { kind } from '@/types/IDevice'

const DeviceIcons = ({ kind }: { kind: kind }) => {
  return (
    <Tag color={DEVICETYPES[kind].color}>
      <Icon color="white" name={DEVICETYPES[kind].icon} />
    </Tag>
  )
}

export default DeviceIcons
