import { Col, Form, Row, Select, Switch } from 'antd'
import useForm from '@hooks/useForm'
import InputWithForm from '@components/common/InputWithForm'
import { useTranslation } from 'react-i18next'
import { ICreateForm } from '@/types/ICreateForm'
import { IDiscovery } from '@/types/IDiscovery'
import { useState } from 'react'
import { useIdc } from '../Tabs'

interface FormDiscoveryProps extends ICreateForm {
  data: IDiscovery | undefined
}

const SelectDc = () => {
  const { dcs } = useIdc()
  const safeDc = dcs.data || []

  const data = safeDc.map((dcItem: any) => ({
    value: dcItem.uuid,
    label: dcItem.dc.shortname
  }))

  const { t } = useTranslation()

  return (
    <Form.Item
      name="discovery_dc"
      label={t('DC')}
      rules={[{ required: true, message: t('requiredItem') }]}
      style={{ marginLeft: '5px' }}
    >
      <Select
        placeholder={t('CHOOSEANITEM')}
        options={data}
        data-cy="select-input"
      />
    </Form.Item>
  )
}

const FormDiscovery = ({
  onClose,
  action,
  data,
  ...props
}: FormDiscoveryProps) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const close = () => {
    form.resetFields()
    onClose()
  }
  let url = `/discovery/device`
  if (data?.uuid) {
    url = `/discovery/device/${data.uuid}`
  }

  const [desactiveted, setDesactiveted] = useState<boolean>(
    data?.active ?? true
  )

  const { onFinish, contextHolder, disabled } = useForm(action, close, url)

  return (
    <Form
      {...props}
      form={form}
      disabled={disabled}
      layout="vertical"
      initialValues={{
        ...data,
        discovery_dc: data?.discovery_dc.uuid ?? undefined
      }}
      onFinish={onFinish}
    >
      {contextHolder}
      <Row gutter={12}>
        <Col xs={{ span: 24 }} xl={{ span: 24 }}>
          <InputWithForm
            {...props}
            rules={[
              {
                required: true,
                pattern: /^[^A-Z]*$/,
                message: t('NAMELOWERCASE')
              }
            ]}
            label={t('NAME')}
            name={'name'}
            placeholder={t('NAME')}
          />
        </Col>
        <Row style={{ width: '100%' }} justify="space-between" align="middle">
          <Col xs={{ span: 24 }} xl={{ span: 12 }}>
            <SelectDc />
          </Col>
          <Col xs={{ span: 24 }} xl={{ span: 12 }}>
            <Form.Item
              initialValue={true}
              name={'active'}
              label={t('ACTIVE')}
              valuePropName="checked"
              style={{ marginLeft: '10px' }}
            >
              <Switch
                onChange={value => {
                  setDesactiveted(value)
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {!desactiveted ? (
          <Col xs={{ span: 24 }} xl={{ span: 24 }}>
            <InputWithForm
              {...props}
              rules={[{ required: true }]}
              label={t('REASON')}
              name={'reason'}
              placeholder={t('REASON')}
            />
          </Col>
        ) : null}
      </Row>
    </Form>
  )
}

export default FormDiscovery
