import Icon from '@components/common/Icon'
import SearchByQueryInput from '@components/common/SearchByQueryInput'
import Title from '@components/common/Title'
import ModalDiscovery from '@components/Discovery/Modal'
import DiscoveryTabs from '@components/Discovery/Tabs'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

function Discovery() {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <>
      <Row
        gutter={[10, 5]}
        style={{ marginBottom: '8px' }}
        justify="space-between"
      >
        <Title name="Discovery" />
        <SearchByQueryInput />
        <Col xs={{ span: 24 }} xl={{ span: 3 }}>
          <ModalDiscovery
                title={t('CREATEDEVICE')}
                action="create"
                style={{ background: theme.green }}
                type="primary"
                icon={<Icon name="fa-light fa-plus" color="white" />}
                block
                id="creatediscovery"
          >
                {t('CREATEDEVICE')}
          </ModalDiscovery>
        </Col>
      </Row>
      <DiscoveryTabs />
    </>
  )
}

export default Discovery
