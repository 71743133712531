import useModal from '@hooks/useModal'
import Modal from '@components/common/Modal'
import { Button, ButtonProps, Form, Row, Select } from 'antd'
import { ISector } from '@/types/ISector'
import InputWithForm from '@components/common/InputWithForm'
import { useTranslation } from 'react-i18next'
import { api } from '@helpers/api'
import { useGenericContext } from '@hooks/useGenericContext'
import { MessageContext } from '@contexts/MessageContext'
import { handleError } from '@helpers/handleError'
import useTableContext from '@hooks/useTableContext'
import useSearchSelect from '@hooks/useSearchSelect'
import { SearchSelectWithForm } from '@components/common/SearchSelectWithForm'
const Option = Select.Option

interface SectorModalProps extends ButtonProps {
  sector?: ISector
}

const generateOption = ({ uuid, name }: ISector) => (
  <Option key={uuid} value={uuid} label={name}>
    {name}
  </Option>
)

const UsersSelect = ({ sector }: { sector: string }) => {
  const searchSectors = (search = '') =>
    api
      .get(`/susers`, { params: { perPage: 299, search } })
      .then(response => response.data.data)

  const { options, ...props } = useSearchSelect({
    initialValue: undefined,
    func: searchSectors
  })

  const handleSelect = (e: string, action: 'delete' | 'post') => {
    const url = `/user/sector/${e}/${sector}`
    api[action](url)
  }

  return (
    <SearchSelectWithForm
      required={false}
      name="users"
      mode="multiple"
      label="USERS"
      onDeselect={e => handleSelect(e, 'delete')}
      onSelect={e => handleSelect(e, 'post')}
      {...props}
    >
      {Array.isArray(options) ? options.map(generateOption) : null}
    </SearchSelectWithForm>
  )
}

const onRequestSector = async (
  isEdit: boolean,
  values: ISector,
  uuid: string | undefined
) => {
  if (isEdit) return await api.put(`/sector/${uuid}`, values)
  return await api.post('/sector', values)
}

const useSectorModal = (
  onCancel: () => void,
  isEdit: boolean,
  uuid: string | undefined,
  sector: ISector | undefined
) => {
  const { t } = useTranslation()
  const messageApi = useGenericContext(MessageContext)
  const { update } = useTableContext()
  const errorMessage = t(isEdit ? 'EDITSECTORERROR' : 'CREATESECTORERROR')
  const onFinish = async (values: any) => {
    const newValue =
      sector?.slug === values.slug ? { name: values.name } : values

    await onRequestSector(isEdit, newValue, uuid)
      .then(e => {
        update(e.data.uuid, e.data)
        messageApi.success(
          `${e.data.name} ${t(!isEdit ? 'CREATED' : 'UPDATED')}`
        )
        onCancel()
      })
      .catch(err => handleError(err, messageApi, errorMessage))
  }
  return { onFinish }
}

const ConfirmButton = (props: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button
      htmlType="submit"
      type="primary"
      {...props}
      data-cy="submit-sector-modal"
    >
      {t('CONFIRM')}
    </Button>
  )
}

const SectorModal = ({ sector, ...props }: SectorModalProps) => {
  const { open, onOpen, onCancel } = useModal()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { onFinish } = useSectorModal(onCancel, !!sector, sector?.uuid, sector)

  const initialValues = sector
    ? {
        ...sector,
        users: sector?.users?.map(e => e.uuid)
      }
    : sector

  return (
    <Form initialValues={initialValues} onFinish={onFinish} form={form}>
      <Modal
        open={open}
        onCancel={onCancel}
        footer={[<ConfirmButton onClick={() => form.submit()} />]}
        title={`${sector ? t('EDIT') : t('CREATE')} ${t('SECTOR')}`}
      >
        <Row>
          <InputWithForm required name="name" label={t('NAME')} />
          <InputWithForm required name="slug" label={t('SLUG')} />

          {sector && <UsersSelect sector={sector.uuid} />}
        </Row>
      </Modal>
      <Button onClick={onOpen} {...props} />
    </Form>
  )
}

export default SectorModal
