import { Space } from 'antd'
import Icon from '@components/common/Icon'
import { useGenericContext } from '@hooks/useGenericContext'
import BalanceDrawer from '../Drawer'
import { IBalance } from '@/types/IBalance'
import { BalanceContext } from '@contexts/BalanceContext'
import { CustomButton } from '@components/Ticket/Modal'

interface ActionsItemsProps {
  data: IBalance
  floatButton?: boolean
}

const BalanceActions = ({ data }: { data: IBalance }) => {
  const crypto = window.crypto
  const array = new Uint32Array(1)
  const numForm = crypto.getRandomValues(array)[0].toString()

  return (
    <Space>
      <BalanceDrawer balance={data} numForm={numForm}>
        <ActionsItems data={data} />
      </BalanceDrawer>
    </Space>
  )
}

export const ActionsItems = ({
  data,
  floatButton,
  ...props
}: ActionsItemsProps) => {
  const { openDrawer } = useGenericContext(BalanceContext)

  return (
    <CustomButton
      {...props}
      block
      icon={<Icon name="far fa-rocket-launch" color="white" />}
      type="primary"
      onClick={() => openDrawer(data)}
      float={floatButton}
      data-cy="create-pod"
    >
      {'Deploy pod'}
    </CustomButton>
  )
}

export default BalanceActions
