import ProductModal from '@components/Products/Modal'
import { useProductstTab } from '@components/Products/Table'
import Icon from '@components/common/Icon'
import Title from '@components/common/Title'
import { Col,  Row, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'

const Products = () => {
  const { t } = useTranslation()
  const items = useProductstTab()
  return (
    <Row>
      <Col span={24}>
        <Row justify="space-between">
          <Title name={t('PRODUCTS')} />
          <ProductModal
            data-cy="create-product-open-modal"
            type="primary"
            icon={<Icon name="fa-light fa-plus" color="white" />}
          >
            {t('CREATEPRODUCT')}
          </ProductModal>
        </Row>
      </Col>
      <Col span={24}>
        <Tabs items={items} style={{ width: '100%' }} />
      </Col>
    </Row>
  )
}

export default Products
