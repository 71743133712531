import Modal from '@components/common/Modal/index.tsx'
import ModalFooter from '@components/common/ModalFooter/index.tsx'
import useModal from '@hooks/useModal'
import { Button, ButtonProps } from 'antd'
import { IDiscovery } from '@/types/IDiscovery'
import FormDiscovery from '../Form'

interface ModalDiscoveryProps extends ButtonProps {
  action: 'create' | 'edit'
  data?: IDiscovery
}

const ModalDiscovery = ({ action, data, ...props }: ModalDiscoveryProps) => {
  const numForm = (
    crypto.getRandomValues(new Uint32Array(1))[0] % 10000
  ).toString()

  const { open, onOpen, onCancel } = useModal()

  return (
    <>
      <Modal
        footer={
          <ModalFooter onClose={onCancel} action={action} form={numForm} />
        }
        width="30%"
        centered
        open={open}
        onCancel={onCancel}
      >
        <FormDiscovery
          id={numForm}
          data={data}
          action={action}
          onClose={onCancel}
        />
      </Modal>
      <Button {...props} onClick={onOpen} />
    </>
  )
}

export default ModalDiscovery
