import { ICompany, IEntity } from '@/types/ICompany'
import { CustomDescriptionType } from '@/types/IDescriptions'
import { ISUser } from '@/types/IUser'
import { Space, Tag, Tooltip, Typography } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import dayjs from 'dayjs'
import { DATEFORMAT } from '@constants/dateformat'
import { useTranslation } from 'react-i18next'

const UserTagList = ({ users }) => {
  if (!users) return null

  const userList = Array.isArray(users) ? users : [users]

  return (
    <Space wrap>
      {userList.map(user => (
        <Tooltip key={user.email} title={user.email}>
          <Tag color="blue-inverse">{user.name}</Tag>
        </Tooltip>
      ))}
    </Space>
  )
}

export const columns: CustomDescriptionType<ICompany>[] = [
  {
    span: 1,
    label: 'NAME',
    key: 'name'
  },
  {
    span: 1,
    label: 'FANTASYNAME',
    key: 'fantasy_name'
  },
  {
    span: 1,
    label: 'CSRESP',
    key: 'csresp',
    render: ({ data }) => data && data.name
  },
  {
    span: 1,
    label: 'SELLER',
    key: 'seller',
    render: ({ data }) => data && data.name
  },
  {
    span: 1,
    label: 'ALIAS',
    key: 'alias',
    render: ({ data }) => <Paragraph>{data}</Paragraph>
  },
  {
    span: 1,
    label: 'CNPJ',
    key: 'docnum'
  },
  {
    span: 1,
    label: 'ENTITY',
    key: 'entity',
    render: ({ data }) => (
      <Space wrap>
        {data
          ? data.map((entity: IEntity) => (
            <Typography.Text>{entity.name}</Typography.Text>
          ))
          : null}
      </Space>
    )
  },
  {
    span: 1,
    label: 'ID',
    key: 'opdc_id'
  },
  {
    span: 2,
    label: 'UUID',
    key: 'uuid'
  },
  {
    span: 1,
    label: 'CEP',
    key: 'cep'
  },
  {
    label: 'ADDRESS',
    key: 'address'
  },
  {
    label: 'CITY',
    key: 'city'
  },
  {
    label: 'COUNTRY',
    key: 'country'
  },
  {
    label: 'ESTATE',
    key: 'estate'
  },
  {
    label: 'PHONES',
    key: 'phones'
  },
  {
    span: 4,
    label: 'USERS',
    key: 'users',
    render: ({ data }) => (
      <Space wrap>
        {data
          ? data.map((user: ISUser) => (
            <Tooltip title={user.email} key={user.uuid}>
              <Tag color="blue-inverse">{user.name}</Tag>
            </Tooltip>
          ))
          : null}
      </Space>
    )
  },
  {
    span: 4,
    label: 'TAGS',
    key: 'tags',
    render: ({ data }) => (
      <Space wrap>
        {data
          ? data.map((tag: string) => (
            <Tag color="lime-inverse" key={tag}>{tag}</Tag>
          ))
          : null}
      </Space>
    )
  },
  {
    span: 4,
    label: 'MANAGER_COMPANY',
    key: 'user_in_charge',
    render: ({ data }) => <UserTagList users={data} />
  },
  {
    span: 1,
    label: 'OBS',
    key: 'obs'
  },
  {
    span: 1,
    label: 'HASSUPPORT',
    key: 'has_support',
    render: ({ data }) => {
      const { t } = useTranslation()

      return data ? <Tag>{t('YES')}</Tag> : <Tag>{t('NO')}</Tag>
    }
  },
  {
    span: 1,
    label: 'RELATIONSHIP',
    key: 'obs_relationship',
    render: ({ data, item }) => (
      <>
        <Paragraph>{data}</Paragraph>
        {item.obs_relationship_updatedat ? (
          <Tag>{dayjs(item.obs_relationship_updatedat).format(DATEFORMAT)}</Tag>
        ) : null}
      </>
    )
  }
]
