import { IEntity } from '@/types/ICompany'
import { createContext, ReactNode, useContext, useMemo, useState } from 'react'

interface IEntityContext {
  entity?: IEntity
  setEntity: (entity: IEntity) => void
  company: string | null
  setCompany: (company: string | null) => void
}

export const EntityContext = createContext<IEntityContext | undefined>(
  undefined
)

export const EntityProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [entity, setEntity] = useState<IEntity>()
  const [company, setCompany] = useState<string | null>(null)

  const memoValue = useMemo(
    () => ({ entity, setEntity, company, setCompany }),
    [entity, setEntity, company, setCompany]
  )
  return (
    <EntityContext.Provider value={memoValue}>
      {children}
    </EntityContext.Provider>
  )
}

export const useEntityContext = () => {
  const context = useContext(EntityContext)
  if (!context) {
    throw new Error('useEntityContext must be used within an EntityProvider')
  }
  return context
}
