import { useEffect, useState } from 'react'
import Title from '@common/Title'
import Filter from '@components/Device/GroupFilter'
import SubTitle from '@common/SubTitle'
import ModalTicket from '@components/Ticket/Modal'
import { useUser } from '@contexts/UserContext'
import { columns } from '@components/Ticket/Table'
import { useTranslation } from 'react-i18next'
import { IDashboardTableCard } from '@/types/IDashboard'
import Alert from '@common/Alert'
import TicketList from '@components/Ticket/TicketList'
import useWindowResize from '@hooks/useWindowResize'
import useTable from '@hooks/useTable'
import Card from '@components/common/Card'
import useFetch from '@hooks/useFetch'
import { IProductPod } from '@/types/IProduct'
import useSocket from '@hooks/useSocket'
import {
  ButtonProps,
  Col,
  Collapse,
  FloatButton,
  Row,
  Space,
  Switch,
  Tag,
  Tooltip,
  Typography
} from 'antd'
import { useGenericContext } from '@hooks/useGenericContext'
import Table from '@components/common/Table'
import { TICKETSTATUSESCOLORS } from '@constants/ticketstatuses'
import Icon from '@components/common/Icon'
import { DisplayContext } from '@contexts/DisplayContext'
import ClearFilterButton from '@components/common/ClearFilterButton'
import Summary from '@components/Dashboard/Summary'
import { Text } from '@components/common/Text'
import DeviceTableList from '@components/Device/DeviceTableList'
import { api } from '@helpers/api'
import OnboardingSection from '@components/OnBoard/OnboardingSection'
import { CollapseProps } from 'antd/lib'
import { useTheme } from 'styled-components'
import BalanceDrawer from '@components/Pod/Drawer'
import { ActionsItems } from '@components/Pod/Actions'
import ModalBalance from '@components/Balance/Modal'
import CardsPod from '@components/Pod/Card'
import RenderPage from '@components/common/RenderPage'

interface PodButtonProps extends ButtonProps {
  floatButton?: boolean
}

const PodButton = ({ floatButton, ...props }: PodButtonProps) => {
  const { balance } = useUser()
  const crypto = window.crypto
  const array = new Uint32Array(1)
  const numForm = crypto.getRandomValues(array)[0].toString()

  return (
    <BalanceDrawer balance={balance} numForm={numForm}>
      <ActionsItems data={balance} floatButton={floatButton} {...props} />
    </BalanceDrawer>
  )
}

const FloatCreateTicket = () => {
  const { balance } = useUser()
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const rocket = balance.enabled && !balance.hasDevices
  const ticket = !balance.enabled || balance.hasDevices

  return (
    <FloatButton.Group
      trigger="click"
      open={open}
      onClick={() => setOpen(!open)}
      type="primary"
      style={{ insetInlineEnd: 24 }}
      icon={<Icon name="far fa-bars" color="white" size={'15'} />}
    >
      {rocket && (
        <PodButton key="pod-button" floatButton={true} type="primary" />
      )}
      {ticket && (
        <ModalTicket
          key="modal-ticket"
          action="create"
          tooltip={t('CREATETICKET')}
          float={true}
          type="primary"
          style={{ right: 50, zIndex: 1 }}
          icon={<Icon name="fal fa-tags" color="white" size={'15'} />}
        />
      )}
    </FloatButton.Group>
  )
}

const defineColor = (name: string) => {
  const keys = Object.keys(TICKETSTATUSESCOLORS)
  const definedKey =
    keys.find(key => key.includes(name.toUpperCase().split(' ')[0])) || 'NEW'
  return TICKETSTATUSESCOLORS[definedKey].color
}

const TableCard = ({ url, name }: IDashboardTableCard) => {
  const { list } = useGenericContext(DisplayContext)
  const props = useTable(url)
  const { width } = useWindowResize()
  if (width < 768) {
    return (
      <>
        <SubTitle>{name}</SubTitle>
        <Card {...props} columns={columns} />
      </>
    )
  }
  return (
    <>
      <SubTitle>
        {name + ' '}{' '}
        {props?.data?.meta?.total ? (
          <Tag
            color={defineColor(name) || 'blue-inverse'}
            style={{ borderRadius: '10px' }}
          >
            {props?.data?.meta?.total}
          </Tag>
        ) : null}
      </SubTitle>
      {list === 'list' ? (
        <TicketList {...props} />
      ) : (
        <Table
          {...props}
          columns={columns}
          handleTableChange={props.handleTableChange}
        />
      )}
    </>
  )
}

const ShowTables = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const uri = '/ticket?status='
  return (
    <>
      <TableCard url={`${uri}NEW`} name={t('NEWTICKETS')} />
      <TableCard url={`${uri}PENDING`} name={t('PENDINGTICKETS')} />
      {user.level !== 1 ? (
        <TableCard
          url={`${uri}PROCESSING_ATTRIBUTED&assignedTo=${user.uuid}`}
          name={t('ATRIBUTEDTICKETS')}
        />
      ) : null}
      <TableCard
        url={`${uri}PROCESSING_ATTRIBUTED,PROCESSING_PLANNED`}
        name={t('PROCESSINGTICKETS')}
      />
    </>
  )
}

const useSwicthDisplayButton = () => {
  const { setList, list } = useGenericContext(DisplayContext)
  const changeList = () => {
    setList(prevList => {
      const newDisplay = prevList === 'list' ? 'table' : 'list'
      localStorage.setItem('dashboardlist', newDisplay)
      return newDisplay
    })
  }
  return { list, changeList }
}

const SwitchDisplayButton = () => {
  const { list, changeList } = useSwicthDisplayButton()
  const { t } = useTranslation()
  return (
    <Col xl={{ span: 24 }} xs={{ span: 24 }}>
      <Row justify="end" gutter={[16, 16]}>
        <Space>
          <Tooltip title={t('CLICKTOCHANGETHEVISUALIZATIONMODEL')}>
            <Text>{list === 'table' ? t('VIEWONTABLE') : t('VIEWONLIST')}</Text>
          </Tooltip>
          <Switch
            data-cy="switch-table-list"
            checkedChildren={<Icon name="fal fa-table-list" color="white" />}
            unCheckedChildren={<Icon name="fal fa-list" color="white" />}
            onClick={changeList}
          />
        </Space>
      </Row>
    </Col>
  )
}

const DashboardTitle = () => {
  const { user, balance } = useUser()
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Title name="Dashboard">
      <Row
        style={{ width: '100%', paddingBottom: 10 }}
        justify="space-between"
        align="middle"
      >
        <Col xs={24} xl={8}>
          <Typography.Title data-cy="title">Dashboard</Typography.Title>

          <Row align="middle" style={{ gap: '10px' }}>
            {user.level > 1 ? (
              <ClearFilterButton uri={'/'} item={'dashboard'} shape="round" />
            ) : null}
          </Row>
        </Col>
        {balance.enabled ? (
          <Col
            xs={24}
            xl={{
              span: 13,
              offset: 3
            }}
          >
            <Row align="middle" justify={'end'} gutter={[8, 8]}>
              <Col xs={24} xl={6}>
                <Typography.Title data-cy="balance" style={{ paddingTop: 10 }}>
                  {`${t('BALANCE')}: ${balance.balance.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 3
                  })}`}
                </Typography.Title>
              </Col>
              <Col xs={24} xl={8}>
                <ModalBalance
                  block
                  type="primary"
                  icon={<Icon name="far fa-dollar-sign" color="white" />}
                  data-cy={`balanceModal`}
                  style={{ paddingInline: 47, background: theme.green }}
                >
                  {t('ADDBALANCE')}
                </ModalBalance>
              </Col>
              <Col xs={24} xl={8}>
                <PodButton style={{ paddingInline: 47 }} />
              </Col>
            </Row>
          </Col>
        ) : null}
      </Row>
    </Title>
  )
}

const DeviceSection = () => {
  const { t } = useTranslation()
  return (
    <>
      <SubTitle style={{ marginTop: '8px' }}>{t('MYDEVICES')}</SubTitle>
      <DeviceTableList />
    </>
  )
}

const UserItems = () => (
  <Col span={24}>
    <Alert style={{ marginBottom: '2%', marginTop: '1%' }} />
    <Filter />
  </Col>
)

const useOnboardData = () => {
  const [onboardStatus, setOnboardStatus] = useState('')
  const [hasFilledForm, setHasFilledForm] = useState(false)
  const { user } = useUser()

  useEffect(() => {
    if (user.level === 1) {
      api.get('/onboard/customer').then(onboardResponse => {
        if (!onboardResponse.data.length) {
          setHasFilledForm(true)
        }
        api.get('/company').then(companyResponse => {
          const company = companyResponse?.data?.data[0]

          if (company) {
            setOnboardStatus(company.onboard_status)
          }
        })
      })
    }
  }, [user.level])

  return { onboardStatus, hasFilledForm }
}

const Dashboard = () => {
  const [list, setList] = useState(() => {
    const current = localStorage.getItem('dashboardlist')
    return current ?? 'list'
  })
  const { user, balance, setBalance } = useUser()
  const { data, loading, error } = useFetch<IProductPod, undefined>({
    func: () => api.get('pod/product'),
    initialValue: [],
    deps: [balance]
  })
  const fetchData = async () => {
    await api.get('/balance').then(res => {
      setBalance(res.data)
    })
  }
  useSocket(fetchData, 'DeviceUpdated')
  const { width } = useWindowResize()
  const { onboardStatus, hasFilledForm } = useOnboardData()
  const { t } = useTranslation()

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: t('DEVICESUMMARY'),
      children: <Summary />
    }
  ]
  return (
    <DisplayContext.Provider value={{ list, setList }}>
      {hasFilledForm &&
        user.level === 1 &&
        onboardStatus === 'WAITING_FIRST_DEVICE_ACCESS_MAIL' ? (
        <OnboardingSection />
      ) : null}
      <FloatCreateTicket />
      <DashboardTitle />
      {balance.enabled ? <Collapse items={items} /> : <Summary />}
      {(balance.enabled && balance.hasDevices) || !balance.enabled ? (
        <>
          <Row
            gutter={[8, 8]}
            style={{
              marginTop: '20px'
            }}
          >
            {user.level === 1 ? <UserItems /> : null}
            {width > 768 ? <SwitchDisplayButton /> : null}
          </Row>
          {user.level === 1 ? <DeviceSection /> : null}
          <ShowTables />
        </>
      ) : (
        <div style={{ marginTop: 30 }}>
          <RenderPage {...{ data, loading, error }}>
            <CardsPod data={data.data} />
          </RenderPage>
        </div>
      )}
    </DisplayContext.Provider>
  )
}

export default Dashboard
