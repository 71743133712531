import { Space, Modal, message } from 'antd'
import Button from '@components/common/Button'
import { api } from '@helpers/api'
import { useTranslation } from 'react-i18next'
import Icon from '@common/Icon'
import { useNavigate } from 'react-router-dom'
import { IEntity } from '@/types/ICompany'
import { DrawerContext } from '@contexts/DrawerContext'
import { useGenericContext } from '@hooks/useGenericContext'
import useConfirmAction from '@hooks/useConfirmAction'
import { AxiosResponse } from 'axios'

type EntityProps = {
  entity: IEntity
}

const Actions = ({ entity }: EntityProps) => {
  return (
    <Space>
      <EntityDrawer entity={entity} />
      <DeleteEntityButton data={entity} />
    </Space>
  )
}

const EntityDrawer = ({ entity }: EntityProps) => {
  return <InternalEntityDrawer entity={entity} />
}

const InternalEntityDrawer = ({ entity }: EntityProps) => {
  const navigate = useNavigate()
  const { openDrawer } = useGenericContext(DrawerContext)
  const onClick = () => {
    navigate(window.location.pathname)
    openDrawer(entity)
  }
  return (
    <Button
      type="primary"
      onClick={onClick}
      icon={<Icon name="fa-light fa-edit" color="white" />}
      data-cy={`drawerEntity`}
    />
  )
}
const confirmDelete = async (
  data: IEntity,
  t: (key: string) => string
): Promise<AxiosResponse> => {
  if (data.companies.length > 1) {
    message.error(t('CANTDELETECOMPANY'), 10)
    return Promise.reject(new Error(t('CANTDELETECOMPANY')))
  }

  return api.delete(`/entity/${data.uuid}`)
}

const renderDeleteButton = (confirm: () => void) => (
  <Button
    id="deleteEntity"
    danger
    icon={<Icon name="fa-light fa-trash" color="white" />}
    onClick={() => confirm()}
  />
)
export const DeleteEntityButton = ({ data }: { data: IEntity }) => {
  const [modal, contextHolder] = Modal.useModal()
  const { t } = useTranslation()
  const { confirm } = useConfirmAction({
    modal,
    func: async () => await confirmDelete(data, t),
    messages: {
      name: data.name,
      title: 'AREYOUSUREDELETECOMPANY',
      onSucess: 'SUCESSDELETECOMPANY',
      onError: 'ERRORDELETECOMPANY'
    },
    descriptions: infoCompany(data).map(item => {
      return { ...item, label: t(item.label) }
    })
  })
  const renderContextHolder = () => contextHolder
  return (
    <>
      {renderContextHolder()}
      {renderDeleteButton(confirm)}
    </>
  )
}

const infoCompany = (data: IEntity) => [
  {
    key: '1',
    span: 3,
    label: 'NAME',
    children: <span>{data.name}</span>
  },
  {
    key: '2',
    span: 3,
    label: 'ALIAS',
    children: <span>{data.alias}</span>
  },
  {
    key: '3',
    span: 3,
    label: 'COMPANIES',
    children: (
      <span>
        {Array.isArray(data.companies) && data.companies.length > 0
          ? data.companies.map(company => company.name).join(', ')
          : ''}
      </span>
    )
  }
]

export default Actions
