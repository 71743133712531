import { Tabs, TabsProps, Tag } from 'antd'
import { generateTabItem } from '@components/common/Tab'
import { ColumnType } from 'antd/es/table'
import useFetch from '@hooks/useFetch'
import { api } from '@helpers/api'
import Loader from '@components/common/Loader'
import { useTranslation } from 'react-i18next'
import ModalDiscovery from '../Modal'
import Icon from '@components/common/Icon'
import { DiscoveryData, DiscoveryDc, DiscoveryDevice } from '@/types/IDiscovery'
import { formatDate } from '@components/Dns/Tabs'

export const useIdc = () => {
  const { data, loading, error } = useFetch<DiscoveryData, undefined>({
    func: () => api.get(`/discovery/dc`)
  })
  return { dcs: data, loading, error }
}

const Active: React.FC<{ active: boolean }> = ({ active }) => {
  const { t } = useTranslation()
  return active ? (
    <Tag color="green">{t('YES')}</Tag>
  ) : (
    <Tag color="red">{t('NO')}</Tag>
  )
}

const columns: ColumnType<DiscoveryDevice>[] = [
  {
    title: 'EDIT',
    key: 'action',
    dataIndex: 'actions',
    render: (_, device) => (
      <ModalDiscovery
        type="primary"
        action="edit"
        icon={<Icon name="fa-light fa-edit" color="white" />}
        data={device}
        data-cy="editDevice"
      />
    )
  },
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'REASON',
    dataIndex: 'reason',
    key: 'reason'
  },
  {
    title: 'ACTIVE',
    dataIndex: 'active',
    key: 'active',
    render: (active: boolean) => <Active active={active} />
  },
  {
    title: 'LASTSYNC',
    dataIndex: 'last_sync_at',
    key: 'last_sync_at',
    render: (data: string) => {
      return data ? formatDate(data) : null
    }
  },
  {
    title: 'CREATEDAT',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (data: string) => formatDate(data)
  },
  {
    title: 'UPDATEAT',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (data: string) => formatDate(data)
  },
  {
    title: 'CREATEDBY',
    dataIndex: 'created_by',
    key: 'created_by',
    render: (_, data) => {
      return data.created_by ? data.created_by.name : null
    }
  },
  {
    title: 'UPDATEBY',
    dataIndex: 'updated_by',
    key: 'updated_by',
    render: (_, data) => {
      return data.updated_by ? data.updated_by.name : null
    }
  }
]

interface TabItem {
  key: string
  label: string
  url: string
}

export const renderingTabsDns = (dc: DiscoveryDc[]) => {
  const { t } = useTranslation()
  const safeDc = dc || []
  const tabData: TabItem[] = safeDc.map((item: DiscoveryDc, index: number) => ({
    key: item.uuid,
    label: t(item.dc.shortname),
    url: `/discovery/device?dcs=${item.uuid}`
  }))

  const renderingDnsTabs: TabsProps['items'] = tabData.map(item =>
    generateTabItem(item, columns)
  )
  return renderingDnsTabs
}

const DiscoveryTabs = () => {
  const { dcs, loading } = useIdc()
  const renderingTabs: TabsProps['items'] = renderingTabsDns(dcs?.data)
  if (loading) return <Loader />

  return (
    <Tabs
      destroyInactiveTabPane={true}
      defaultActiveKey="1"
      items={renderingTabs}
      type="card"
      size="large"
    />
  )
}

export default DiscoveryTabs
