import { Row, Card, Tag, Space, Typography, Col, Flex, Tooltip } from 'antd'
import { useTheme } from 'styled-components'
import { IProductPod } from '@/types/IProduct'
import TextMarkdown from '@components/common/TextMarkdown'
import { useUser } from '@contexts/UserContext'
import ModalBalance from '@components/Balance/Modal'
import Icon from '@components/common/Icon'
import { useTranslation } from 'react-i18next'
import ModalPod from '../Modal'

interface CardsPodProps {
  data: IProductPod[]
  onClose?: () => void
}
interface CardProductsPod {
  item: IProductPod
  onClose?: () => void
}
const CardsPod = ({ data, onClose }: CardsPodProps) => {
  return (
    <Flex justify="space-around" wrap gap="small">
      {data?.map((item: IProductPod) => (
        <CardProductsPod onClose={onClose} key={item.uuid} item={item} />
      ))}
    </Flex>
  )
}

const CardProductsPod = ({ item, onClose }: CardProductsPod) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { user } = useUser()
  const totalPrice = (item.price * item.discounted) / 100 + item.price
  return (
    <Card
      title={
        <Flex justify="space-around" wrap align="center">
          <Typography.Title level={5}>{item.title}</Typography.Title>
          {!item.available ? (
            <Tooltip title={t('NOTAVAILABLE')}>
              <ModalPod onClose={onClose} data={item}>
                {t('ADDPOD')}
              </ModalPod>
            </Tooltip>
          ) : (
            <>
              {item.canBuy ? (
                <ModalPod onClose={onClose} data={item}>
                  {t('ADDPOD')}
                </ModalPod>
              ) : (
                <ModalBalance
                  block
                  type="primary"
                  icon={<Icon name="far fa-dollar-sign" color="white" />}
                  data-cy="drawerPod"
                  style={{
                    width: 200,
                    marginBottom: 5,
                    background: theme.green
                  }}
                >
                  {t('ADDBALANCE')}
                </ModalBalance>
              )}
            </>
          )}
        </Flex>
      }
      style={{ width: 500 }}
    >
      <Row>
        <Col span={12}>
          <TextMarkdown text={item.description} />
        </Col>
        <Col span={12}>
          <Flex
            vertical
            style={{
              width: '100%',
              height: '100%'
            }}
            justify="flex-end"
            align="flex-end"
          >
            {item.discounted &&
            user.tags.find(item => item === 'PARTNER_EDUCATION') ? (
              <Space>
                <Tag
                  color={theme.green}
                  bordered={false}
                >{`${item.discounted}% OFF`}</Tag>
                <Typography.Text
                  delete
                  style={{ fontSize: 17, color: theme.gray }}
                >
                  ${totalPrice.toFixed(3)}
                </Typography.Text>
              </Space>
            ) : null}
            <Typography.Text style={{ fontSize: 22, color: theme.green }}>
              ${item.price.toFixed(3)}
            </Typography.Text>
          </Flex>
        </Col>
      </Row>
    </Card>
  )
}

export default CardsPod
