import { useEntityContext } from '@contexts/Entity'
import { ICompany } from '@/types/ICompany'
import { ColumnType } from 'antd/es/table'
import { TableWithoutQuery } from '@components/common/Table'
import { useTranslation } from 'react-i18next'
import useConfirmAction from '@hooks/useConfirmAction'
import Button from '@components/common/Button'
import Icon from '@components/common/Icon'
import { AxiosResponse } from 'axios'
import { api } from '@helpers/api'
import { IDevice } from '@/types/IDevice'
import { useEffect, useState } from 'react'
import { message, Modal } from 'antd'
import { CompanyTag, DeviceTag } from '@components/Ticket/Table'
import { Space } from 'antd/lib'
import { UserInCharge } from '@components/Company/Table'

export const TableAssociation = () => {
  const { entity } = useEntityContext()
  return (
    <>
      {entity?.companies.map(company => (
        <TableWithoutQuery
          columns={columns}
          data={company}
          key={company.uuid}
        />
      ))}
    </>
  )
}

export const columns: ColumnType<ICompany>[] = [
  {
    key: 'uuid',
    title: 'ACTIONS',
    dataIndex: 'uuid',
    render: (_, company) => <DeleteAssociatonButton data={company} />
  },
  {
    key: 'name',
    title: 'NAME',
    dataIndex: 'name',
    render: (_, company) => <CompanyTag company={company} />
  },
  {
    key: 'user_in_charge',
    title: 'USER_IN_CHARGE',
    dataIndex: 'user_in_charge',
    render: user_in_charge => (
      <>
        {!user_in_charge?.name ? (
          <> </>
        ) : (
          <UserInCharge user={user_in_charge} />
        )}{' '}
      </>
    )
  },
  {
    key: 'alias',
    title: 'DEVICES',
    dataIndex: 'uuid',
    render: (uuid: string) => <ListOfDevices url={`/device?company=${uuid}`} />
  }
]

const confirmEntity = async (
  data: ICompany,
  entityuuid: string
): Promise<AxiosResponse> => {
  return await api.delete(`/entity/company/${entityuuid}/${data.uuid}`)
}

export const DeleteAssociatonButton = ({ data }: { data: ICompany }) => {
  const { entity } = useEntityContext()
  const [modal, contextHolder] = Modal.useModal()
  const { t } = useTranslation()
  const { confirm } = useConfirmAction({
    modal,
    func: async () => {
      if (!entity?.uuid) {
        return Promise.reject(new Error('Entity UUID not found'))
      }
      return await confirmEntity(data, entity.uuid)
    },
    messages: {
      name: data.name,
      title: 'AREYOUSUREDELETEASSOCIATION',
      onSucess: 'SUCESSDELETEASSOCIATION',
      onError: 'ERRORDELETECOMPANY'
    },
    descriptions: infoAssociation(data, t).map(item => {
      return { ...item, label: t(item.label) }
    })
  })
  return (
    <>
      {contextHolder}
      <Button
        id="deleteAssociation"
        danger
        icon={<Icon name="fa-light fa-trash" color="white" />}
        onClick={confirm}
      />
    </>
  )
}

const infoAssociation = (data: ICompany, t: (key: string) => string) => [
  {
    key: '1',
    span: 3,
    label: 'NAME',
    children: <span>{data.name}</span>
  },
  {
    key: '2',
    span: 3,
    label: 'ALIAS',
    children: <span>{data.alias}</span>
  },
  {
    key: '3',
    span: 3,
    label: 'WARN',
    children: <span>{t('LOSEACCESSDEVICE')}</span>
  }
]

const ListOfDevices = ({ url }: { url: string }) => {
  const [data, setData] = useState<IDevice[]>([])
  const { t } = useTranslation()

  const fetchConstants = async () => {
    try {
      const response = await api.get(url)
      setData(response.data.data)
    } catch (error) {
      message.error(t('GETFORMDATAERROR'))
    }
  }

  useEffect(() => {
    fetchConstants()
  }, [url])

  return (
    <ul>
      {Array.isArray(data) && data.length > 0 ? (
        data.map(item => (
          <Space
            key={item.uuid}
            style={{
              padding: '5px'
            }}
          >
            {<DeviceTag device={item} />}
          </Space>
        ))
      ) : (
        <></>
      )}
    </ul>
  )
}
