import { Button, ButtonProps, Popconfirm, Space, Tooltip, message } from 'antd'
import { api } from '@helpers/api'
import ModalFrame from '../ModalFrame'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '@components/common/Icon'
import { useTheme } from 'styled-components'
import { IDevice } from '@/types/IDevice'
import useTableContext from '@hooks/useTableContext'
import useDeviceContext from '@hooks/useDeviceContext'
import { useUser } from '@contexts/UserContext'
import IconButton from '@components/common/IconButton'

interface ActionsProps {
  data: IDevice
}

interface ActionButtonProps extends ActionsProps, ButtonProps {
  disabled: boolean
  setDisabled: Dispatch<SetStateAction<boolean>>
}

const success = (mes: string) => message.success(mes)

const error = (mes: string) => message.error(mes)

const stateDevice = {
  poweroff: {
    action: 'poweroff',
    title: 'POWEROFFDEVICE',
    describe: 'AREYOUSUREDEVICEOFF'
  },
  poweron: {
    action: 'poweron',
    title: 'POWERONDEVICE',
    describe: 'AREYOUSUREDEVICEON'
  }
}

export const translatedMessages = (
  t: (key: string) => string,
  action: string,
  name: string
) => {
  const states = {
    poweron: 'POWERED',
    poweroff: 'POWEREDOFF',
    reset: 'RESETED'
  }

  const defaultMessage = (str: string) =>
    `${t('DEVICE')} ${name} ${t(str)} ${t(
      states[action as keyof typeof states]
    )}`

  return {
    success: defaultMessage('WAS'),
    error: defaultMessage('WASNOT')
  }
}

const postPowered = async (action: string, uuid: string) => {
  return await api.post(`/device/${uuid}/${action}`)
}

export const handlePowered = async (
  action: string,
  uuid: string,
  message: { error: string; success: string }
) => {
  return await postPowered(action, uuid)
    .then(e => {
      success(message.success)
      return e.data
    })
    .catch(e => {
      error(message.error)
    })
}

const PowerButton = ({ data, setDisabled }: ActionButtonProps) => {
  const [power, setPower] = useState(data.state === 'poweredOn')
  const { update } = useTableContext()
  const { updateDevice } = useDeviceContext()
  const theme = useTheme()
  const { t } = useTranslation()

  const state = power ? stateDevice.poweroff : stateDevice.poweron
  const message = translatedMessages(t, state.action, data.name)

  const handlePower = async () => {
    setDisabled(true)
    try {
      const result = await handlePowered(state.action, data.uuid, message)
      if (result) {
        handleSuccess(result)
      }
    } finally {
      setDisabled(false)
    }
  }

  const handleSuccess = (result: IDevice) => {
    update(data.uuid, result)
    updateDevice(data.uuid, result)
    setPower(result.state === 'poweredOn')
  }

  return (
    <Popconfirm
      disabled={data.state === 'suspended'}
      title={t(state.title)}
      description={t(state.describe)}
      onConfirm={handlePower}
    >
      <Tooltip
        title={power ? t('POWEROFFDEVICE') : t('POWERONDEVICE')}
        placement="bottom"
      >
        <Button
          data-cy={`device-${data.state}`}
          type="link"
          style={{ backgroundColor: !power ? theme.green : theme.red }}
          icon={<Icon name="fa-light fa-power-off" color="white" />}
        />
      </Tooltip>
    </Popconfirm>
  )
}

const RebootButton = ({
  data,
  disabled,
  setDisabled,
  ...props
}: ActionButtonProps) => {
  const { t } = useTranslation()
  const message = translatedMessages(t, 'reset', data.name)
  const { update } = useTableContext()
  const handleReset = () => {
    setDisabled(true)
    handlePowered('reset', data.uuid, message).then(e => {
      update(data.uuid, e)
      setDisabled(false)
    })
  }
  return (
    <Popconfirm
      disabled={disabled}
      title={t('RESETDEVICE')}
      description={t('AREYOUSURERESETDEVICE')}
      onConfirm={handleReset}
    >
      <Tooltip title={t('RESETDEVICE')} placement="bottom">
        <Button
          icon={<Icon name="fa-light fa-arrows-retweet" color="white" />}
          data-cy={`device-reset`}
          type="primary"
          style={{ color: 'white' }}
        />
      </Tooltip>
    </Popconfirm>
  )
}

const StateActions = ({ data }: ActionsProps) => {
  const [disabled, setDisabled] = useState(data.state !== 'poweredOn')

  return (
    <Space size={4} style={{ marginLeft: 4 }}>
      <PowerButton data={data} disabled={disabled} setDisabled={setDisabled} />
      {data.state === 'poweredOn' ? (
        <RebootButton
          data={data}
          disabled={disabled || data.type === 'BAREMETAL'}
          setDisabled={setDisabled}
        />
      ) : null}
    </Space>
  )
}

const ConsoleButton = ({ data }: ActionsProps) => {
  const { t } = useTranslation()
  return (
    <>
      {data.state === 'poweredOn' ? (
        <Tooltip title={t('ACCESSDEVICE')} placement="bottom">
          <ModalFrame
            icon="fa-light fa-tv"
            link={`/device/${data.uuid}/console?wss=true`}
            method="post"
          />
        </Tooltip>
      ) : null}
    </>
  )
}
const ConsuptionButton = ({ data }: ActionsProps) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const isPartner = user?.tags?.some(item => item === 'PARTNER_EDUCATION')
  const serviceLink = data?.metadata?.instance?.access?.access[0]?.url
  const handleClick = () => {
    window.location.href = serviceLink
  }
  return (
    <>
      <Tooltip
        title={isPartner ? t('ACCESS') : t('ANALYZECONSUPTION')}
        placement="bottom"
      >
        {isPartner && serviceLink && data.state === 'Running' ? (
          <IconButton
            name="far fa-external-link"
            data-cy="pod-link-button"
            onClick={handleClick}
          />
        ) : null}
        {!isPartner && (
          <ModalFrame
            icon="fa-light fa-monitor-waveform"
            link={
              isPartner ? serviceLink : `/device/${data.uuid}/graphcomplete`
            }
          />
        )}
      </Tooltip>
    </>
  )
}
const DeviceActions = ({ data }: ActionsProps) => {
  const { user } = useUser()
  return (
    <>
      {data.type === 'VM' ? (
        <Space size={4}>
          {Array.isArray(user?.roles) && user?.roles?.includes('RO') ? null : (
            <>
              <StateActions data={data} />
              <ConsoleButton data={data} />
            </>
          )}
          <ConsuptionButton data={data} />
        </Space>
      ) : (
        <ConsuptionButton data={data} />
      )}
    </>
  )
}

export default DeviceActions
