import { PropsWithChildren, useEffect, useState } from 'react'
import Icon from '@components/common/Icon'
import Modal from '@components/common/Modal'
import { ModalContext } from '@contexts/ModalContext'
import { api } from '@helpers/api'
import useModal from '@hooks/useModal'
import { Alert, Button, Col, Form, FormInstance, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { handleError } from '@helpers/handleError'
import { useGenericContext } from '@hooks/useGenericContext'
import { Pool } from '@/types/AutoDeploy'
import PoolProvider, { PoolContext } from '@contexts/PoolContext'
import AvaliableResources from '../AvaliableResources'
import { MessageContext } from '@contexts/MessageContext'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { TFunction } from 'i18next'
import { MessageInstance } from 'antd/es/message/interface'
import {
  DCSelect,
  DiskTypeSegmented,
  RacksSelect
} from '../CreateServerSelects'
import CompanySelect from '@components/common/CompanySelect'

type AutoDeploy = {
  company: string
  DC: string
  rack: string
  diskType: 'nvme' | 'ssd'
  diskSize: number
  cpu: number
  mem: number
  template: string
}

const ServerFormItems = () => {
  const DC = Form.useWatch('DC')
  const rack = Form.useWatch('rack')
  const diskType = Form.useWatch('diskType')
  return (
    <>
      <Col span={24}>
        <CompanySelect
          name="company"
          rules={[{ required: true, message: 'requiredItem' }]}
        />
      </Col>
      <DCSelect />
      <RacksSelect disabled={!DC} />
      {rack ? <DiskTypeSegmented /> : null}
      {DC && diskType ? <AvaliableResources /> : null}
    </>
  )
}

const onCreateServer = async (
  values: AutoDeploy,
  messageApi: MessageInstance,
  t: TFunction,
  navigate: NavigateFunction
) => {
  const { DC, rack, company } = values
  messageApi.loading(t('INIALIZATINGAUTODEPLOY'), 0.8)
  return api
    .post(`/autodeploy/${DC}/${rack}/${company}`, values)
    .then(e => {
      messageApi.success(t('VMCREATEDREDIRECTINGTOTICKET'), 1, () =>
        navigate(`/ticket/${e.data.tkid}`)
      )
    })
    .catch(e => handleError(e, messageApi, t('Auto Deploy')))
}

const useModalContent = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [disabled, setDisabled] = useState(false)
  const messageApi = useGenericContext(MessageContext)
  const onFinish = async (values: AutoDeploy) => {
    setDisabled(true)
    await onCreateServer(values, messageApi, t, navigate).finally(() => {
      setDisabled(false)
    })
  }

  return { onFinish, disabled }
}

const checkAvailibily = (
  pool: Pool | undefined,
  values: Pick<AutoDeploy, 'cpu' | 'mem' | 'diskType' | 'diskSize'>
) => {
  if (pool && pool.hosts.length) {
    const { cpu, mem, diskType, diskSize } = values
    return pool.hosts.filter(
      host =>
        host.cpu >= cpu &&
        host.mem >= mem &&
        host.disks[diskType]?.available_capacity &&
        host.disks[diskType]?.available_capacity / Math.pow(2, 30) >= diskSize
    ).length
  }
  return 0
}

const useWarningMessage = (form: FormInstance) => {
  const [message, setMessage] = useState(false)
  const context = useGenericContext(PoolContext)
  const [cpu, mem, diskType, diskSize] = [
    'cpu',
    'mem',
    'diskType',
    'diskSize'
  ].map(item => Form.useWatch(item, form))

  useEffect(() => {
    if (cpu && mem && diskSize) {
      const items = checkAvailibily(context?.pool, {
        cpu,
        mem,
        diskSize,
        diskType
      })
      items === 0 && setMessage(true)
      return
    } else {
      setMessage(false)
    }
  }, [cpu, mem, diskSize, diskType])

  return message
}

const WarningNotification = () => {
  const { t } = useTranslation()
  return (
    <Col span={24}>
      <Alert
        showIcon
        type="warning"
        message={t('THEREISNOHOSTWITHTHISRESOURCES')}
        description={t('YOUWILLNEEDTOFINISHTHECREATIONOFVM')}
      />
    </Col>
  )
}

const ActionButtons = () => {
  const { t } = useTranslation()
  return (
    <Col span={24} style={{ marginTop: '8px' }}>
      <Row justify="space-between">
        <Button data-cy="clear-create-server" htmlType="reset">
          {t('CLEAR')}
        </Button>
        <Form.Item noStyle>
          <Button data-cy="finish-creation" htmlType="submit" type="primary">
            {t('CREATE')}
          </Button>
        </Form.Item>
      </Row>
    </Col>
  )
}

const ModalContent = () => {
  const [form] = Form.useForm()
  const { onFinish, disabled } = useModalContent()
  const message = useWarningMessage(form)
  return (
    <Form form={form} onFinish={onFinish} disabled={disabled}>
      <Row style={{ width: '100%' }}>
        <ServerFormItems />
        {message ? <WarningNotification /> : null}
        <ActionButtons />
      </Row>
    </Form>
  )
}

const CreateServerWrapper = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation()
  const { onOpen, onCancel, open } = useModal()
  return (
    <ModalContext.Provider value={{ onOpen, open, onCancel }}>
      <PoolProvider>
        <Modal
          {...{ open, onCancel }}
          title={t('CREATESERVER')}
          footer={null}
          width={'50%'}
        >
          <ModalContent />
        </Modal>
        {children}
      </PoolProvider>
    </ModalContext.Provider>
  )
}

const CreateServerButton = () => {
  const { onOpen } = useGenericContext(ModalContext)
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Button
      data-cy="open-modal-create-server"
      icon={<Icon name="fa-light fa-rocket" color="white" />}
      onClick={onOpen}
      block
      style={{ backgroundColor: theme.green, color: 'white' }}
    >
      {t('CREATESERVER')}
    </Button>
  )
}
const CreateServer = () => {
  return (
    <CreateServerWrapper>
      <Col xs={{ span: 24 }} xl={{ offset: 5, span: 3 }}>
        <CreateServerButton />
      </Col>
    </CreateServerWrapper>
  )
}

export default CreateServer
