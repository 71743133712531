import { Select, Form } from 'antd'
import useSearchSelect from '@hooks/useSearchSelect'
import { api } from '@helpers/api'
import { useTranslation } from 'react-i18next'
import { useGenericContext } from '@hooks/useGenericContext'
import { MessageContext } from '@contexts/MessageContext'
import { handleError } from '@helpers/handleError'
import type { IOption } from '@/types/IOption'

const useCompany = () => {
  const { t } = useTranslation()
  const messageApi = useGenericContext(MessageContext)
  const selectProps = useSearchSelect({
    func: async (search?: string) =>
      await api
        .get(`/company?search=${search ?? ''}`)
        .then(e => e.data.data)
        .catch(e => handleError(e, messageApi, t('ERRORCALLCOMPANY')))
  })
  return selectProps
}

const CompanySelect = ({ name, ...props }: { name: string }) => {
  const { t } = useTranslation()
  const { options: companies, ...selectProps } = useCompany()
  const options: IOption[] = companies?.map(company => ({
    value: company.uuid,
    label: company.name
  }))

  return (
    <Form.Item name={name} label={t(`${name.toUpperCase()}`)} {...props}>
      <Select
        {...selectProps}
        showSearch
        allowClear
        filterOption={false}
        data-cy="company-select-create-server"
        options={options}
      ></Select>
    </Form.Item>
  )
}

export default CompanySelect
