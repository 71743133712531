import { Dispatch, SetStateAction } from 'react'
import type { IOrder, IItemOrderRequest } from '@/types/IOrder'

export const calculateOrderPrice = (
  data: IOrder | undefined,
  setTotalPrice: Dispatch<SetStateAction<number>>
): void => {
  let total = 0

  data?.items?.forEach(item => {
    item?.order_request?.items?.forEach(order => {
      total += calculateItemPrice(order)
    })
  })
  setTotalPrice(calcDiscounts(data, total))
}

const calculateItemPrice = (item: IItemOrderRequest): number => {
  if (item.children?.items?.length) {
    return item.children.items.reduce((acc, child) => {
      const selectedChildItem = item?.item?.children.find(
        order => order.uuid === child?.item.uuid
      )
      if (!selectedChildItem) return acc

      const quantity = child.current ?? 1
      return acc + quantity * selectedChildItem.price
    }, 0)
  }

  return checkBoolItem(item)
}

const checkBoolItem = (item: IItemOrderRequest): number => {
  if (item.item.type !== 'ITEM' && item.is_used !== true) return 0
  else return item.current * item.item.price
}

const calcDiscounts = (data: IOrder | undefined, total: number): number => {
  let discountedTotal = total

  const discountTerm = data?.orderTerm?.find(
    ({ duration_in_months }) => duration_in_months === data.duration_in_months
  )

  if (discountTerm)
    discountedTotal -= (total * discountTerm.discount_percent) / 100

  if (data?.discount_percents)
    discountedTotal -= (discountedTotal * data.discount_percents) / 100

  return discountedTotal
}
