const Status = () => {
  return (
    <iframe
      src="https://status.opendata.center/status/home"
      width="100%"
      height="100%"
      title="OPEN DATACENTER STATUS"
      loading="eager"
    ></iframe>
  )
}

export default Status
