import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { api } from '@helpers/api'
import { MessageInstance } from 'antd/es/message/interface'
import { message } from 'antd'
import { ICompany } from '@/types/ICompany'
import {
  enableExitConfirmation,
  disableExitConfirmation
} from '@helpers/exitConfirmation'
import { AxiosResponse } from 'axios'
import { TFunction } from 'i18next'
import { IUser } from '@/types/IUser'

const useCompanyForm = (action: string, close: () => void, data?: ICompany) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [disabled, setDisabled] = useState(false)
  const { t } = useTranslation()

  const onFinish = async (newObject: any) => {
    const values = removedNull(newObject)
    setDisabled(true)
    if (action === 'edit') {
      await handleCompanyUpdate(values, messageApi, t, data).finally(() => {
        setDisabled(false)
        close()
      })
    } else {
      const response = await handleCompanyCreation(
        values,
        messageApi,
        t
      ).finally(() => {
        setDisabled(false)
      })

      response && close()
    }
  }

  return { onFinish, disabled, contextHolder }
}

const handleCompanyUpdate = async (
  values: any,
  messageApi: MessageInstance,
  t: TFunction,
  data: ICompany
) => {
  const { users: usersData, ...companyData } = values
  await fetchWithFeedback(
    messageApi,
    api.put(`/company/${data?.uuid}`, companyData),
    t,
    'company'
  )

  for (const userData of usersData) {
    const changedUserData = getChangedUserData(userData, data)
    if (!userData.hasOwnProperty('uuid') === true) {
      userData.entity = data.entity[0].uuid
      const response = await postUserWithFeedback({...userData, tags: companyData.tags}, messageApi, t)
      userData['uuid'] = response.uuid
    }
    if (Object.keys(changedUserData).length !== 0) {
      await fetchWithFeedback(
        messageApi,
        api.put(`/user/${userData.uuid}`, {...changedUserData, tags: values.tags}),
        t,
        'user'
      )
    }
    putCompanyUserInCharge(userData, data.uuid, messageApi, t)
  }
}

const handleCompanyCreation = async (
  values: any,
  messageApi: MessageInstance,
  t: TFunction
) => {
  const { users: usersData, ...companyData } = values
  const response = await fetchWithFeedback(
    messageApi,
    api.post(`/company`, companyData),
    t,
    'company'
  )

  if (response?.entity && usersData) {
    for (const userData of usersData) {
      userData.entity = response.entity[0].uuid
      const user = await postUserWithFeedback({...userData, tags: values.tags}, messageApi, t)
      userData['uuid'] = user.uuid
      putCompanyUserInCharge({...userData, tags: values.tags}, response.uuid, messageApi, t)
    }
  }
  return response
}

const getChangedUserData = (userData: any, data?: ICompany) => {
  return Object.fromEntries(
    Object.entries(userData).filter(
      ([key, value]) =>
        data &&
        data.users &&
        data.users.find(
          user => user.uuid === userData.uuid && user[key] !== value
        )
    )
  )
}

const messageFeedback = {
  company: { success: 'SUCCESSREQUESTCOMPANY', error: 'ERRORREQUESTCOMPANY' },
  user: { success: 'SUCCESSREQUESTUSER', error: 'ERRORREQUESTUSER' }
}

const removedNull = (object: any) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null)
  )
}
const fetchWithFeedback = async (
  messageApi: MessageInstance,
  func: Promise<AxiosResponse<any, any>>,
  t: TFunction,
  name: keyof typeof messageFeedback
) => {
  enableExitConfirmation()
  message.loading(t('LOADINGREQUEST'), 1)
  return await func
    .then(async response => {
      messageApi.success(
        `${t(messageFeedback[name].success)}:${response.data.name}`,
        3
      )
      return response.data
    })
    .catch(response => {
      messageApi.error(
        `${t(messageFeedback[name].error)}: ${response.response.data.message}`,
        5
      )
    })
    .finally(() => disableExitConfirmation())
}

const postUserWithFeedback = async (
  userData: IUser,
  messageApi: MessageInstance,
  t: TFunction
) => {
  enableExitConfirmation()
  message.loading(t('LOADINGREQUEST'), 1)
  return await api
    .post('/user', userData)
    .then(async response => {
      messageApi.success(`${t('SUCCESSREQUESTUSER')}:${response.data.name}`, 3)
      return response.data
    })
    .catch(response => {
      messageApi.error(`${response.response.data.message}`, 6)
    })
    .finally(() => disableExitConfirmation())
}

const putCompanyUserInCharge = async (
  user: IUser,
  companyUuid: string,
  messageApi: MessageInstance,
  t: TFunction
) => {
  if (user.user_in_charge === true) {
    await fetchWithFeedback(
      messageApi,
      api.put(`/company/${companyUuid}`, { user_in_charge: user?.uuid }),
      t,
      'company'
    )
  }
}

export default useCompanyForm
