import { ModalContext } from '@contexts/ModalContext'
import useModal from '@hooks/useModal'
import { ButtonProps, Form } from 'antd'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '@components/common/Modal/index.tsx'
import { CustomButton } from '@components/Ticket/Modal/index.tsx'
import FormBalance from '../FormBalance'

interface ModalDnsProps extends Omit<ButtonProps, 'type'> {
  type?: string
}

const ModalBalance = ({ ...props }: ModalDnsProps) => {
  const { t } = useTranslation()
  const { open, onCancel, onOpen } = useModal()
  const [disabled] = useState(false)
  const memoOpen = useMemo(
    () => ({ open, onCancel, onOpen }),
    [open, onCancel, onOpen]
  )
  const [step, setStep] = useState(false)
  const numForm = (
    crypto.getRandomValues(new Uint32Array(1))[0] % 10000
  ).toString()
  const [form] = Form.useForm()

  const cancelModal = () => {
    form.resetFields()
    onCancel()
  }

  const openModal = () => {
    setStep(false)
    onOpen()
  }

  return (
    <ModalContext.Provider value={memoOpen}>
      <Modal
        title={t('ADDBALANCE')}
        open={open}
        footer={<></>}
        onCancel={cancelModal}
        centered
        width="45%"
      >
        <FormBalance
          step={step}
          setStep={setStep}
          onCancel={onCancel}
          numForm={numForm}
          form={form}
        />
      </Modal>
      <CustomButton
        disabled={disabled}
        data-cy={`modal-balance`}
        onClick={openModal}
        {...props}
      />
    </ModalContext.Provider>
  )
}

export default ModalBalance
