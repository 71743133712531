import { InputNumber, Col, Form } from "antd"
import { ProductMinorMaxInputProps } from "@/types/IProduct"
import { useTranslation } from "react-i18next"

function validateNumericField(
  value: number,
  min: number,
  max: number
): string | null {
  if (value < min) {
    return 'THEVALUEMUSTBEMORETHANTHEMINIUM';
  } else if (value > max) {
    return 'THEVALUEMUSTBELESSTHANTHEMAXIMIUM';
  }
  return null;
}

function validateBooleanField(
  name: string,
  min: number,
  max: number
): string | null {
  if ((name === 'min' && min !== 0) || (name === 'max' && max !== 1)) {
    return 'THETYPEBOOLACCEPTONLYZEROTOMINANDONETOMAX';
  }
  return null;
}

function validateValue(
  name: string,
  value: number,
  path: string[],
  getFieldValue: <T, >(path: string[]) => T
): string | null {
  const type: string = getFieldValue([...path, 'type'])
  const min: number = getFieldValue([...path, 'min'])
  const max: number = getFieldValue([...path, 'max'])

  if (type === 'BOOL') {
    return validateBooleanField(name, min, max);
  }
  return validateNumericField(value, min, max);
}




export const ProductMinorMaxInput = ({
  field,
  name,
  path
}: ProductMinorMaxInputProps) => {
  const { t } = useTranslation()
  const validate = (
    value: number,
    path: string[],
    getFieldValue: <T, >(path: string[]) => T,
  ) => validateValue(name, value, path, getFieldValue);

  return (
    <Col span={3}>
      <Form.Item
        name={[field, name]}
        initialValue={name !== 'min' ? 1 : 0}
        dependencies={[
          name !== 'min' && [...path, 'min'],
          name !== 'max' && [...path, 'max'],
          name !== 'default' && [...path, 'default']
        ]}
        label={t(name.toUpperCase())}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const error = validate(value, path, getFieldValue);
              if (error) {
                return Promise.reject(new Error(t(error)));
              }
              return Promise.resolve();
            }
          }),
          { required: true }
        ]}
      >
        <InputNumber min="0" step="0" />
      </Form.Item>
    </Col>
  )
}

export default ProductMinorMaxInput
